import React from "react";
import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import PatientExperience from "./components/patient-experience/PatientExperience";
import AnalysisLibrary from "./components/analysis-library/AnalysisLibrary";
import Explore from "./components/explore/Explore";
import ConcurrentSession from "./components/concurrent-session/ConcurrentSessionModal";
import Home from "./components/Home";
import SurveyQuestions from "./components/survey-questions/SurveyQuestions";

function AppRoutes() {
  function MissingRoute() {
    
    return <Navigate to={{ pathname: "/explore" }} />;
  }


  return (
    <Routes>
      <Route exact path="/analysis-library" element={<AnalysisLibrary />} />
      <Route path="/patient-experience" element={<PatientExperience />} />
      <Route path="/explore" element={<Explore />} />
      <Route path="/home" element={<Home />} />
      <Route path="/session" element={<ConcurrentSession />} />

      <Route path="/survey-questions" element={<SurveyQuestions />}/>
      <Route path="*" element={<MissingRoute />} />
    </Routes>
  );
}

export default AppRoutes;
