import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";

const ConcurrentSession = (props) => {
  const navigate = useNavigate();
  const idmUrl = process.env.REACT_APP_IDM_URL;
  const [showHome, setShowHome] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    sessionStorage.setItem("new_session_request", JSON.stringify(true));
    setShowHome(true);
    setIsModalOpen(false);
     navigate("/home");
  };

  const handleCancel = () => {
    //sessionStorage.setItem("loggedout", "yes");
    sessionStorage.removeItem("sessionId");
    window.location.replace(idmUrl);
    setIsModalOpen(false);
  };

  return !showHome && (
    <Modal
      closable={false}
      maskClosable={false} 
      title="Sorry, only one session can be in use at a time."
      open={isModalOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close This Session
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          style={{ backgroundColor: "#27A6A4" }}
        >
          Continue On This Session
        </Button>,
      ]}
    >
      <p>You have too many sessions open for Patient Experience Bank.</p>
    </Modal>
  );
};

export default ConcurrentSession;
