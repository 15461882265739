import React, { useState, useEffect } from "react";
import "./ShareAnalysis.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin, Modal } from "antd";
import {
  getUsersEmail,
  ShareAnalysisToUser,
} from "../../services/ExperienceBankAPI";
import { useSelector, useDispatch } from "react-redux";
import { NotificationTypes } from "../../shared/Notification/Notification";
import { setNotificationData } from "../../reducer/PEBCommonSlice";

const { Option } = Select;

const ShareAnalysis = (props) => {
  const landingPageUrl = process.env.REACT_APP_LANDING_PAGE_URL;
  // const secretKey = process.env.REACT_APP_SECRET_KEY;
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details
  const [isModalOpen, setIsModalOpen] = useState(props.visible);
  const [emailList, setEmailList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const usersEmailList = async () => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: props?.rowDetail?.analysis_id,
    };
    let response = await getUsersEmail(payload);

    setEmailList(response.data);
  };

  const shareAnalysisMail = async (rowData) => {
    setLoading(true);
    const payload = {
      user_id: userInfo?.user_id,
      user_email: userInfo?.user_email,
      user_full_name: userInfo?.user_full_name,
      to_user: selectedItems || [],
      analysis_id: rowData?.analysis_id,
      analysis_name: rowData?.analysis_name,
      page_url: landingPageUrl + "?id=" + rowData.analysis_id,
    };
    let response = await ShareAnalysisToUser(payload);
    if (response?.status === "SUCCEEDED")
      dispatch(
        setNotificationData({
          type: NotificationTypes.SUCCESS,
          message: response?.message,
        })
      );
    else {
      dispatch(
        setNotificationData({
          type: NotificationTypes.ERROR,
          message: "Analysis Failed to Share! Please try again.",
        })
      );
    }
    setLoading(false);
  };

  const handleChange = (selectedEmails) => {
    const selectedUsers = emailList.filter((user) =>
      selectedEmails.includes(user.email_id)
    );

    setSelectedItems(selectedUsers);
  };

  const handleOk = () => {
    shareAnalysisMail(props.rowDetail);
    setIsModalOpen(false);
    props.onClose();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    props.onClose();
  };

  useEffect(() => {
    usersEmailList();
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <>
      <Spin indicator={antIcon} spinning={loading}>
        <Modal
          title="Share Analysis"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Share"
          okButtonProps={{ disabled: selectedItems.length > 0 ? false : true }}
          className="modal-share"
        >
          <p id="modal-label">
            Share a <label>read-only access</label> link to the email(s)
            selected below.
          </p>

          <div>
            <label className="email-label">Email Address</label>
            <Select
              mode="multiple"
              style={{
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              placeholder="Please Select"
              onChange={(email) => handleChange(email)}
            >
              {emailList.map((email) => (
                <Option
                  key={email.email_id}
                  value={email.email_id}
                  label={email}
                >
                  {email.email_id}
                </Option>
              ))}
            </Select>
          </div>
        </Modal>
      </Spin>
    </>
  );
};

export default ShareAnalysis;
