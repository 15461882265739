import axios from "axios";
import axiosInterceptor from './AxiosInterceptor';

// export const BaseUrl = ENVIRONMENTS[SELECTED_ENVIRONMENT].baseurl || "";
export const baseUrl = process.env.REACT_APP_BASE_API_URL;
export const idmUrl = process.env.REACT_APP_IDM_URL;

axiosInterceptor();
//to get geograpghy chart data (World Map)
export const getGeographyChart = async (payload) => {
  try {
    const { data } = await axios.post(`${baseUrl}/geography-chart`, payload, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get Therapy Area chart data (Bar Chart)
export const getTherapyAreaChart = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/therapy-area-chart`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};
export const getTaSubIndication = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/ta-ind-subind-chart`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get Indication chart data (Bar Chart)
export const getIndicationChart = async (payload) => {
  try {
    const { data } = await axios.post(`${baseUrl}/indication-chart`, payload, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get Sub-Indication chart data (Bar Chart)
export const getSubIndicationChart = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/sub-indication-chart`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get Age chart data (Bar Chart)
export const getAgeChart = async (payload) => {
  try {
    const { data } = await axios.post(`${baseUrl}/age-chart`, payload, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get Gender chart data (Bar Chart)
export const getGenderChart = async (payload) => {
  try {
    const { data } = await axios.post(`${baseUrl}/gender-chart`, payload, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get Demographics chart data (Bar Chart)
export const getDemographicsChart = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/demographics-chart`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get Patient Funnel chart data (Bar Chart)
export const getPatientFunnelChart = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/explore-patient-funnel`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get Categories with Questions that Have 25+ Respondents data
export const getQuestionsRespondentData = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/greater-respondents-data`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get filter dropdown values/list
export const getExploreFilterDropdownDataAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/explore-filter-dropdown`,
      payload,
      {
        withCredentials: true,
      }
    );
    
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to save explore filter
export const saveExploreFilterAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/explore-save-filters-data`,
      payload,
      {
        withCredentials: true,
      }
    );
    
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to save explore filter
export const displayExploreFilterDataAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/explore-display-filters-data`,
      payload,
      {
        withCredentials: true,
      }
    );
    
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to Download explore data
export const downloadExploreResponses = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/download-explore-responses`,
      payload,
      {
        withCredentials: true,
      }
    );
    
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};
