import React, { useState, useEffect } from "react";
import { Col, Row, Checkbox, Dropdown, Divider } from "antd";
import "./AnalysisLibrary.scss";
import { Input, Space, Button, Spin } from "antd";
import SharedTable from "../../shared/shared-table/SharedTable";
import AnalysisFilterDrawer from "../filter-drawer/AnalysisFilterDrawer";
import { useNavigate } from "react-router-dom";
import SortIcon from "../../assets/icons/sort-icon.svg";
import FilterIcon from "../../assets/icons/filter-icon.svg";
import FindingReportSettingsModal from "../finding-report-setting-modal/FindingReportSettingsModal";
import {
  getAnalysisLibrary,
  getDisplayFilterData,
} from "../../services/ExperienceBankAPI";
import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  setFilterApplyData,
  setIsFindingsReportFullWidth,
  setNavigationTab,
} from "../../reducer/PEBCommonSlice";
import FindingsDrawer from "../findings-drawer/FindingsDrawer";
import { useRef } from "react";
const { Search } = Input;

function AnalysisLibrary() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details
  const filterAppliedData = useSelector(
    (state) => state.PEBCommon?.filterApplyData
  );
  const isOpen = useSelector((state) => state.PEBCommon?.findingsReport.isOpen);

  const refreshAnalysisLists = useSelector(
    (state) => state.PEBCommon?.refreshAnalysisList
  );
  // const filterApiData = useSelector((state) => state.PEBCommon?.filterAPIData);
  const navigate = useNavigate();
  let [data, setData] = useState([]);
  let [totalAnalysisCount, setTotalAnalysisCount] = useState();
  let [quesMaxUpdatedTs, setQuesMaxUpdatedTs] = useState();
  let [filterActive, setFilterActive] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortValue, setSortValue] = useState("analysis_name");
  const [filterFlag, setFilterFlag] = useState(false);
  const [viewMyAnalysisFlag, setViewMyAnalysisFlag] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [isOpenModal, setIsOpen] = useState(false);

  const supportingData = useSelector(
    (state) => state.PEBCommon?.supportingData
  );
  const filterData = useSelector((state) => state.PEBCommon?.filterData);
  const recordData = useSelector((state) => state.PEBCommon?.recordData);
  //create payload for the Analysis library API
  const analysisListPayload = (
    searchTxt,
    myAnalysisFlag,
    sortVal,
    filterVal,
    offsetVal
  ) => {
    let payload = {
      user_id: userInfo.user_id,
      search_string: searchTxt,
      limit: 4,
      offset: offsetVal * 4 - 4,
      sort_column: sortVal,
      sort_order: sortVal == "last_updated_ts" ? "desc" : "asc",
      view_my_analysis_flag: myAnalysisFlag,
      has_filter_data: false,
      therapeutic_area: filterVal.therapeutic_area || [],
      indication: filterVal.indication || [],
      program: filterVal.program || [],
      study: filterVal.study || [],
      use_case: filterVal.use_case || [],
      created_by: filterVal.created_by || [],
      geography: filterVal.geography || [],
      age: filterVal.age || [],
      race: filterVal.race || [],
      gender: filterVal.gender || [],
      sub_indication: filterVal.sub_indication || [],
      ethnicity: filterVal?.ethnicity ? filterVal?.ethnicity : [],
    };
    analysisList(payload);
  };

  //API call for the Analysis Library data
  const analysisList = async (payload) => {
    setLoading(true);
    let response = await getAnalysisLibrary(payload);
    setTotalAnalysisCount(response.data.total_count);
    setQuesMaxUpdatedTs(response?.data?.ques_max_updated_ts);
    setData(response.data.analysis_list);
    setFilteredData(response.data.analysis_list);
    setLoading(false);
  };

  //API call for the Analysis Library data
  const displayFilterList = async () => {
    const payload = {
      user_id: userInfo.user_id,
      screen_name: "landing",
    };
    let response = await getDisplayFilterData(payload);
    dispatch(setFilterApplyData(response.data));
    setViewMyAnalysisFlag(response.data.view_my_analysis);
    setFilterActive(response.data?.view_my_analysis);
    setSortValue(response.data?.sort_column);

    analysisListPayload(
      searchText,
      response.data.view_my_analysis,
      response.data?.sort_column,
      response.data,
      currentPage
    );
    //setFilterActive(response.data?.view_my_analysis[0]);
  };

  useEffect(() => {
    setSearchText("");
    displayFilterList();
    // analysisListPayload();
  }, [currentPage, refreshAnalysisLists, isOpen]);

  //when clicked on See my Analysis only CHECKBOX
  const checkboxTicked = (e) => {
    let flag = e.target.checked;
    setFilterActive(flag);
    const pageNo = 1;
    analysisListPayload(searchText, flag, sortValue, filterAppliedData, pageNo);
    // setCurrentPage(1);
  };

  const renderData = filterActive ? filteredData : data;

  const debounceIdRef = useRef(null);

  const onSearch = (e) => {
    const searchString = e.target.value;
    // Clear any existing timeout
    clearTimeout(debounceIdRef.current);
    const pageNo = 1;
    // Set a new timeout
    debounceIdRef.current = setTimeout(() => {
      if (searchString.length > 2 || searchString.length === 0) {
        setSearchText(searchString);
        setCurrentPage(1);
        analysisListPayload(
          searchString,
          filterActive,
          sortValue,
          filterAppliedData,
          pageNo
        );
      }
    }, 1000);
  };

  const items = [
    {
      label: "Analysis Name",
      key: "analysis_name",
    },
    {
      label: "Created By",
      key: "created_by",
    },
    {
      label: "Last Updated",
      key: "last_updated_ts",
    },
    {
      label: "Use Case",
      key: "use_case",
    },
  ];

  //sorting dropdown item clciked
  const onClick = (e) => {
    setSortValue(e.key);
    analysisListPayload(
      searchText,
      filterActive,
      e.key,
      filterAppliedData,
      currentPage
    );
  };
  useEffect(() => {
    dispatch(setIsFindingsReportFullWidth(true));
    dispatch(setNavigationTab("/analysis-library"));
  }, []);

  //to open filter drawer
  const filterDrawer = (val, state) => {
    setFilterFlag(state);
    if (!state && val) {
      analysisListPayload(
        searchText,
        filterActive,
        sortValue,
        val,
        currentPage
      );
    }
  };

  const createReport = () => {
    setIsOpen(true);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  if (isOpen) {
    return (
      <FindingsDrawer
        isShrinkable={false}
        isClosable={true}
        isExpandable={false}
      />
    );
  }

  return (
    <>
      {isOpenModal && (
        <FindingReportSettingsModal
          isOpen={isOpenModal}
          setIsOpen={setIsOpen}
          supporting_data={supportingData}
          filterData={filterData}
          recordData={recordData}
          type={"category_responses"}
          addFlag={false}
          createAddFlag={true}
        />
      )}
      <div style={{ padding: "24px 14px" }}>
        <Spin indicator={antIcon} spinning={loading}>
          <Row>
            <Col span={24}>
              <p className="sub-header"> Filter and Report Library</p>
            </Col>
            <Col flex={3}>
              <Space direction="vertical" style={{ float: "left" }}>
                <Search
                  placeholder="Search"
                  onChange={onSearch}
                  size={"large"}
                  enterButton={false}
                  style={{ width: 350, borderRadius: "0px" }}
                />
              </Space>
              <Dropdown
                menu={{
                  items,
                  onClick,
                  selectable: true,
                  defaultSelectedKeys: [sortValue],
                }}
                trigger={["click"]}
                overlayStyle={{ width: 200, alignItems: "left" }}
              >
                <Button
                  className="configure-btn"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt={"Sort Icon"}
                    src={SortIcon}
                    style={{ verticalAlign: "text-bottom", paddingRight: 5 }}
                  />
                  <Space>Sort</Space>
                </Button>
              </Dropdown>
              <Button
                className="configure-btn"
                onClick={() => filterDrawer("", true)}
              >
                <img
                  alt={"Filter Icon"}
                  src={FilterIcon}
                  style={{ verticalAlign: "text-bottom", paddingRight: 5 }}
                />
                Filter Reports
              </Button>
              <Divider type="vertical" className="divider-vertical" />
              <span className="checbox-style">
                {" "}
                <Checkbox onChange={checkboxTicked} checked={filterActive}>
                  View My Reports Only
                </Checkbox>
              </span>
            </Col>
            <Col flex={2}>
              <Space style={{ float: "right" }}>
                <Button style={{height:40}}
                  type={
                    !localStorage.getItem("analysisId") ? "primary" : undefined
                  }
                  icon={<PlusCircleOutlined />}
                  className={
                    !localStorage.getItem("analysisId")
                      ? "analysis-btn"
                      : undefined
                  }
                  onClick={() => createReport()}
                >
                  Create Report
                </Button>
              </Space>
            </Col>
          </Row>
          <Row>
            {totalAnalysisCount === 0 ? (
              <div style={{ margin: "auto", padding: 200 }}>
                No Results Found
              </div>
            ) : (
              <Col span={24}>
                <SharedTable
                  data={data}
                  filteredData={renderData}
                  totalCount={totalAnalysisCount}
                  currentPageNo={currentPage}
                  setPageData={setCurrentPage}
                  quesMaxUpdatedTs={quesMaxUpdatedTs}
                ></SharedTable>
              </Col>
            )}
          </Row>

          {filterFlag ? (
            <div>
              <AnalysisFilterDrawer
                visible={true}
                onClose={(val) => filterDrawer(val, false)}
              />
            </div>
          ) : (
            ""
          )}
        </Spin>
      </div>
    </>
  );
}

export default AnalysisLibrary;
