import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Drawer,
  Col,
  Row,
  Dropdown,
  Space,
  Checkbox,
  Input,
  theme,
  Spin,
  Tooltip,
} from "antd";
import { DownOutlined, CloseOutlined } from "@ant-design/icons";
import {
  getFilterDropdownDataAPI,
  saveFilterDataAPI,
  getDisplayFilterData,
} from "../../services/ExperienceBankAPI";
import { useSelector, useDispatch } from "react-redux";
import { setFilterApplyData } from "../../reducer/PEBCommonSlice";
const CheckboxGroup = Checkbox.Group;
const { useToken } = theme;
const { Search } = Input;

const AnalysisFilterDrawer = (props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details
  const [open, setOpen] = useState(props.visible);
  const [size, setSize] = useState();
  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };
  const onClose = (val) => {
    setOpen(false);
    props.onClose(val);
  };

  useEffect(() => {
    showLargeDrawer();
  }, [showLargeDrawer]);

  useEffect(() => {
    displayFilterDataPayload();
  }, []);

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    padding: "0.5rem",
    minWidth: "95%",
    maxWidth: "100%",
  };
  const menuStyle = {
    boxShadow: "none",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "middle",
    padding: "0.5rem 0",
    maxHeight: "250px",
    overflowY: "auto",
    marginTop: "10px",
    marginBottom: "10px",
  };

  const userId = userInfo.user_id;
  const defaultCheckedList = [];
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const checkAll = filteredItems.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < filteredItems.length;
  const onChange = (list) => {
    // final = final + filteredItems&&list - filteredItems&&!list
    setCheckedList((prev) => {
      const toAdd = filteredItems.filter((val) => list.includes(val));
      const toRemove = filteredItems.filter((val) => !list.includes(val));
      let finalList = [...new Set([...prev, ...toAdd])];
      finalList = finalList.filter((val) => !toRemove.includes(val));

      return [...finalList];
    });
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? filteredItems : []);
  };
  const [filterCategoryData, setFilterCategoryData] = useState([
    {
      key: "1",
      categoryKey: "therapeutic_area",
      categoryLabel: "Therapy Area",
      span: 12,
      openState: false,
      selectedValue: [],
      selectAll: true,
    },
    {
      key: "2",
      categoryKey: "use_case",
      categoryLabel: "Use Case",
      span: 12,
      openState: false,
      selectedValue: [],
      selectAll: true,
    },
    {
      key: "3",
      categoryKey: "indication",
      categoryLabel: "Indication",
      span: 12,
      openState: false,
      selectedValue: [],
      selectAll: true,
    },
    {
      key: "4",
      categoryKey: "sub_indication",
      categoryLabel: "Sub-Indication",
      span: 12,
      openState: false,
      selectedValue: [],
      selectAll: true,
    },
    {
      key: "5",
      categoryKey: "program",
      categoryLabel: "Program",
      span: 12,
      openState: false,
      selectedValue: [],
      selectAll: true,
    },
    {
      key: "6",
      categoryKey: "study",
      categoryLabel: "Study",
      span: 12,
      openState: false,
      selectedValue: [],
      selectAll: true,
    },
    {
      key: "7",
      categoryKey: "created_by",
      categoryLabel: "Created By",
      span: 24,
      openState: false,
      selectedValue: [],
      selectAll: true,
    },
    {
      key: "8",
      categoryKey: "geography",
      categoryLabel: "Geography",
      span: 12,
      openState: false,
      selectedValue: [],
      selectAll: true,
    },
    {
      key: "9",
      categoryKey: "age",
      categoryLabel: "Age",
      span: 12,
      openState: false,
      selectedValue: [],
      selectAll: true,
    },
    {
      key: "10",
      categoryKey: "race",
      categoryLabel: "Race",
      span: 12,
      openState: false,
      selectedValue: [],
      selectAll: true,
      isInfoIcon: true
    },
    {
      key: "11",
      categoryKey: "gender",
      categoryLabel: "Sex at Birth",
      span: 12,
      openState: false,
      selectedValue: [],
      selectAll: true,
    },
    {
      key: "12",
      categoryKey: "ethnicity",
      categoryLabel: "Ethnicity",
      span: 12,
      openState: false,
      selectedValue: [],
      selectAll: true,
    },
  ]);

  const [dropdownLoading, setDropdownLoading] = useState(true);

  const handleOpenChange = (flag, category) => {
    if (flag) {
      setItems([]);
      setFilteredItems([]);
      setCheckedList([]);
      setSearchString("");
      getDropdownPayload(category);
    }
    const newState = filterCategoryData.map((filter) => {
      if (filter.categoryKey === category) {
        filter.openState = flag;
      }
      return filter;
    });

    setFilterCategoryData([...newState]);
  };

  const [searchString, setSearchString] = useState("");
  const onSearch = (event) => {
    const searchString = event.target.value;
    setSearchString(searchString);
    if (searchString.length >= 3) {
      const filteredList = items.filter((item) =>
        item.toLowerCase().includes(searchString.toLowerCase().trim())
      );

      setFilteredItems([...filteredList]);
    } else {
      setFilteredItems([...items]);
    }
  };

  const okHandler = (category) => {
    const newState = filterCategoryData.map((filter) => {
      if (filter.categoryKey === category) {
        filter.selectedValue = checkedList;
        filter.selectAll = items.length === checkedList.length;
      }
      return filter;
    });

    setFilterCategoryData([...newState]);
    handleOpenChange(false, category);

    // dispatch(setFilterApplyData(filterCategoryData));
  };

  const getDropdownPayload = (column) => {
    let indication = [];
    let therapeutic_area = [];
    let sub_indication = [];
    switch (column) {
      case "therapeutic_area":
        indication = filterCategoryData.filter(
          (category) => category.categoryKey === "indication"
        )[0]?.selectedValue;
        sub_indication = filterCategoryData.filter(
          (category) => category.categoryKey === "sub_indication"
        )[0]?.selectedValue;
        break;
      case "indication":
        therapeutic_area = filterCategoryData.filter(
          (category) => category.categoryKey === "therapeutic_area"
        )[0]?.selectedValue;
        sub_indication = filterCategoryData.filter(
          (category) => category.categoryKey === "sub_indication"
        )[0]?.selectedValue;
        break;
      case "sub_indication":
        therapeutic_area = filterCategoryData.filter(
          (category) => category.categoryKey === "therapeutic_area"
        )[0]?.selectedValue;
        indication = filterCategoryData.filter(
          (category) => category.categoryKey === "indication"
        )[0]?.selectedValue;
        break;
      default:
        break;
    }
    setDropdownLoading(true);
    let payload = {
      user_id: userId,
      search_string: "",
      screen_name: "landing",
      // limit: 100,
      // offset: 0,
      column_name: column,
      indication,
      therapeutic_area,
      sub_indication,
    };
    getFilterDropdownData(payload);
  };
  //API call for Dropdown Data
  const getFilterDropdownData = async (payload) => {
    let response = await getFilterDropdownDataAPI(payload);
    let newItems = [];
    if (response?.data.list_data?.length) {
      newItems = response.data.list_data.map((item) => {
        return item.value;
      });
    }
    filterCategoryData.forEach((filter) => {
      if (filter.categoryKey === payload.column_name) {
        setCheckedList(
          filter.selectedValue?.length
            ? [...filter.selectedValue]
            : [...newItems]
        );
      }
    });
    setSearchString("");
    setItems([...newItems]);
    setFilteredItems([...newItems]);
    setDropdownLoading(false);
  };

  const displayFilterDataPayload = (column) => {
    let payload = {
      user_id: userId,
      screen_name: "landing",
    };
    displayFilterData(payload);
  };
  //API call for Display Dropdowns Data
  const displayFilterData = async (payload) => {
    let response = await getDisplayFilterData(payload);

    //Setting Selected Values
    const newState = filterCategoryData.map((filter) => {
      filter.selectedValue = response.data[filter.categoryKey];
      filter.selectAll = response.data[filter.categoryKey]?.length
        ? false
        : true;
      return filter;
    });
    setFilterCategoryData([...newState]);
  };

  const saveFilterDataPayload = () => {
    const use_case_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "use_case"
    )[0];
    const use_case = use_case_details.selectAll
      ? []
      : use_case_details.selectedValue;

    const indication_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "indication"
    )[0];
    const indication = indication_details.selectAll
      ? []
      : indication_details.selectedValue;

    const sub_indication_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "sub_indication"
    )[0];
    const sub_indication = sub_indication_details.selectAll
      ? []
      : sub_indication_details.selectedValue;

    const therapeutic_area_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "therapeutic_area"
    )[0];
    const therapeutic_area = therapeutic_area_details.selectAll
      ? []
      : therapeutic_area_details.selectedValue;

    const program_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "program"
    )[0];
    const program = program_details.selectAll
      ? []
      : program_details.selectedValue;

    const geography_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "geography"
    )[0];
    const geography = geography_details.selectAll
      ? []
      : geography_details.selectedValue;

    const study_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "study"
    )[0];
    const study = study_details.selectAll ? [] : study_details.selectedValue;

    const created_by_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "created_by"
    )[0];
    const created_by = created_by_details.selectAll
      ? []
      : created_by_details.selectedValue;

    const age_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "age"
    )[0];
    const age = age_details.selectAll ? [] : age_details.selectedValue;

    const race_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "race"
    )[0];
    const race = race_details.selectAll ? [] : race_details.selectedValue;

    const gender_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "gender"
    )[0];
    const gender = gender_details.selectAll ? [] : gender_details.selectedValue;
    const ethnicity_details = filterCategoryData.filter(
      (filter) => filter.categoryKey === "ethnicity"
    )[0]; 
    const ethnicity = ethnicity_details.selectAll ? [] : ethnicity_details.selectedValue;
    let payload = {
      user_id: userId,
      screen_name: "landing",
      use_case,
      indication,
      sub_indication,
      therapeutic_area,
      program,
      geography,
      study,
      created_by,
      age,
      race,
      gender,
      ethnicity
    };
    saveFilterData(payload);
  };
  //API call to Save Dropdowns Data
  const saveFilterData = async (payload) => {
    let response = await saveFilterDataAPI(payload);

    onClose(payload);
    dispatch(setFilterApplyData(payload));
  };

  const filterResetHandler = () => {
    const newState = filterCategoryData.map((filter) => {
      filter.selectedValue = [];
      filter.selectAll = true;
      return filter;
    });
    setFilterCategoryData([...newState]);
  };

  return (
    <>
      <Drawer
        title={`Filter Reports`}
        placement="right"
        size={size}
        onClose={() => onClose(false)}
        open={open}
        extra={
          <Space>
            <CloseOutlined onClick={() => onClose(false)} />
          </Space>
        }
        footer={
          <Row justify="end" gutter={16}>
            <Col>
              <Button onClick={filterResetHandler}>Reset</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={saveFilterDataPayload}>
                Ok
              </Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={[16, 16]}>
          {filterCategoryData.map((category, index) => {
            return (
              <Col
                key={index}
                span={category.span}
                className="gutter-row"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "0.5rem",
                }}
              >
                <strong>{category.categoryLabel}</strong>
                <Dropdown
                  style={{ width: "90%" }}
                  dropdownRender={() => (
                    //TO FIX: PASS VIA MENU TO SOLVE Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?
                    <Spin spinning={dropdownLoading}>
                      <div style={contentStyle}>
                        <Search
                          placeholder={`Search ${category.categoryLabel}`}
                          allowClear
                          onChange={onSearch}
                          value={searchString}
                          size={"large"}
                          enterButton={false}
                          style={{ borderRadius: "0px" }}
                        />
                        {React.cloneElement(
                          filteredItems.length ? (
                            <div>
                              {filteredItems?.length === items?.length && (
                                <Checkbox
                                  indeterminate={indeterminate}
                                  onChange={onCheckAllChange}
                                  checked={checkAll}
                                  style={{
                                    width: "100%",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                  }}
                                >
                                  Select All
                                </Checkbox>
                              )}
                              <CheckboxGroup
                                // options={filteredItems}
                                options={filteredItems.map((item) => ({
                                  label: (
                                    <Tooltip title={item}>
                                      <span>{item}</span>
                                    </Tooltip>
                                  ),
                                  value: item,
                                }))}
                                value={checkedList}
                                onChange={onChange}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                  fontSize: "14px !important",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                              />
                            </div>
                          ) : dropdownLoading ? (
                            <div>
                              <div
                                style={{
                                  textAlign: "center",
                                  padding: "1rem 0",
                                }}
                              >
                                Loading...
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div
                                style={{
                                  textAlign: "center",
                                  padding: "1rem 0",
                                }}
                              >
                                No Records Found
                              </div>
                            </div>
                          ),
                          {
                            style: menuStyle,
                          }
                        )}
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <Button
                            type="default"
                            onClick={() =>
                              onCheckAllChange({ target: { checked: true } })
                            }
                          >
                            Reset
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => okHandler(category.categoryKey)}
                            disabled={!checkedList.length}
                          >
                            OK
                          </Button>
                        </Space>
                      </div>
                    </Spin>
                  )}
                  trigger={["click"]}
                  onOpenChange={(state) =>
                    handleOpenChange(state, category.categoryKey)
                  }
                  open={category.openState}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ width: "100%" }}
                  >
                    {/* <Space style={{ width: "100%" }}> */}
                    <Input
                      size="large"
                      placeholder="Select"
                      title={
                        category.selectAll
                          ? "All"
                          : category.selectedValue?.join(", ")
                      }
                      style={{ width: "100%" }}
                      value={
                        category.selectAll
                          ? "All"
                          : category.selectedValue?.join(", ")
                      }
                      suffix={<DownOutlined />}
                    />
                    {/* </Space> */}
                  </a>
                </Dropdown>
              </Col>
            );
          })}
        </Row>
      </Drawer>
    </>
  );
};

export default AnalysisFilterDrawer;
