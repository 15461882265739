import React from "react";
//import "./PatientExperience.scss";

function PatientExperience() {
  return (
    <>
      <h1>Patient Experience</h1>
    </>
  );
}

export default PatientExperience;
