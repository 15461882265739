import React, { useState } from "react";
import "./SharedTable.scss";
import { Card, Row, Col, Pagination, Modal, Tooltip, Badge } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ViewData from "../../assets/icons/view-data.svg";
import EditIcon from "../../assets/icons/zs-icon-edit-fill.svg";
import EditIconDiabled from "../../assets/icons/zs-icon-edit-fill-diabled.svg";
import CopyIcon from "../../assets/icons/copy.svg";
import ShareIcon from "../../assets/icons/share.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import DeleteDiabledIcon from "../../assets/icons/delete-disabled.svg";
import GeographyIcon from "../../assets/icons/geography-icon.svg";
import DmographicIcon from "../../assets/icons/demographic-icon.svg";
import UseCaseIcon from "../../assets/icons/use-case-icon.svg";
import ShareAnalysis from "../../components/share-analysis/ShareAnalysis";
import { useDispatch, useSelector } from "react-redux";
import {
  setFindingsReportAnalysisData,
  setFindingsReportAnalysisId,
  setIsFindingsReportFullWidth,
  setIsFindingsReportIsOpen,
  setRefreshAnalysisList,
  setNotificationData,
  setHasAccessToReport,
} from "../../reducer/PEBCommonSlice";
import {
  copyAnalysis,
  deleteCreatedAnalysis,
} from "../../services/ExperienceBankAPI";
import { NotificationTypes } from "../../shared/Notification/Notification";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";

const SharedTable = (props) => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details
  const maxItems = 1;
  const { confirm } = Modal;
  // const [currentPage, setCurrentPage] = useState(props.currentPageNo);
  const pageSize = 4;
  const startIndex = (props.currentPageNo - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = props.filteredData;
  const dispatch = useDispatch();
  const [rowDetail, setRowDetail] = useState();
  const [shareFlag, setShareFlag] = useState(false);

  const handleChangePage = (page) => {
    props.setPageData(page);
  };

  const shareModalOpen = (state, row) => {
    setShareFlag(state);
    setRowDetail(row);
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let id = searchParams.get("id");
  useEffect(() => {
    if (id) {
      handleClick(id);
    }
  }, [id]);

  const handleClick = (
    id,
    description = "",
    name = "",
    last_updated = "",
    edit_access_flag = false
  ) => {
    dispatch(setFindingsReportAnalysisId(id));
    dispatch(setIsFindingsReportIsOpen(true));
    // if (true) {
    localStorage.setItem("analysisId", id);
    // dispatch(setFindingsReportAnalysisId(id));
    // } else {
    // localStorage.removeItem("analysisId");
    // }
    dispatch(setIsFindingsReportFullWidth(true));
    dispatch(setHasAccessToReport(edit_access_flag));
    dispatch(
      setFindingsReportAnalysisData({
        id,
        name,
        description,
        last_updated,
        quesMaxUpdatedTs: props.quesMaxUpdatedTs,
        edit_access_flag,
      })
    );
  };
  const handleViewDataClick = (
    id,
    description = "",
    name = "",
    last_updated = "",
    edit_access_flag = false
  ) => {
    dispatch(setFindingsReportAnalysisId(id));

    dispatch(setIsFindingsReportIsOpen(true));
    localStorage.setItem("analysisId", id);

    dispatch(setHasAccessToReport(edit_access_flag));

    dispatch(
      setFindingsReportAnalysisData({
        id,
        name,
        description,
        last_updated,
        quesMaxUpdatedTs: props.quesMaxUpdatedTs,
        edit_access_flag: edit_access_flag,
      })
    );
    navigate("/explore", { state: { tabValue: "2", edit_access_flag } });
  };

  // ------- API Calls --------

  //Delete Analysis
  const deleteAnalysis = async (id, analysis_name) => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: id,
      screen_name: "analysis",
      analysis_name,
    };
    const response = await deleteCreatedAnalysis(payload);
    dispatch(setRefreshAnalysisList(response));
    dispatch(
      setNotificationData({
        type: NotificationTypes.SUCCESS,
        message: response?.message,
      })
    );
  };

  //Copy Analysis
  const createCopyAnalyis = async (id, name) => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: id,
      analysis_name: name,
      screen_name: "analysis",
    };

    let response = await copyAnalysis(payload);
    dispatch(setRefreshAnalysisList(response?.result));
    dispatch(
      setNotificationData({
        type: NotificationTypes.SUCCESS,
        message: response?.message,
      })
    );
  };

  //Confirmation Popup for Deleting an Analysis
  const showConfirm = (id, name) => {
    confirm({
      title: "Are you sure you want to delete this analysis?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Deleting an analysis is final and all associated analysis findings will be removed.",
      okText: "Delete",
      className: "modal-delete",
      onOk() {
        deleteAnalysis(id, name);
      },
      onCancel() {},
    });
  };

  return (
    <div style={{ marginTop: 20 }}>
      {currentPageData.map((item, index) => (
        <Card
          key={index}
          style={{
            width: "100%",
            marginBottom: 16,
            height: 145,
          }}
        >
          <Row className="card-row-1">
            <Col span={12}>
              <div style={{ textAlign: "left" }}>
                <span>
                  <a
                    style={{ fontSize: 16, fontWeight: 600 }}
                    onClick={() =>
                      handleClick(
                        item.analysis_id,
                        item.analysis_description,
                        item.analysis_name,
                        item.analysis_refresh_date,
                        userInfo.user_email === item.created_by_email_id
                      )
                    }
                  >
                    {item.analysis_name}
                  </a>
                </span>{" "}
                <br />
                <span
                  style={{ fontSize: 14, fontWeight: 400, color: "#716E79" }}
                >
                  Created By:{" "}
                  <label
                    style={{ fontSize: 14, fontWeight: 600, color: "#716E79" }}
                  >
                    {item.created_by} | {item.created_date}
                  </label>
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ textAlign: "right" }}>
                {userInfo.user_email === item.created_by_email_id &&
                !item.analysis_lock_flag ? (
                  <a>
                    <Tooltip title="Edit Report">
                      <img
                        alt={"Edit Report"}
                        src={EditIcon}
                        onClick={() =>
                          handleViewDataClick(
                            item.analysis_id,
                            item.analysis_description,
                            item.analysis_name,
                            item.analysis_refresh_date,
                            userInfo.user_email === item.created_by_email_id
                          )
                        }
                      />
                    </Tooltip>
                  </a>
                ) : (
                  <a>
                    <Tooltip
                      title={
                        (userInfo.user_email === item.created_by_email_id) &&
                        item.analysis_lock_flag ? (
                          <>
                            Explore and other functionalities are not active<br />
                            for reports created before 06/09/2024.
                          </>
                        ) : (
                          <>
                            Must be owner of <br />
                            the report to edit.
                          </>
                        )
                      }
                    >
                      <img
                        alt={"Edit Disabled"}
                        style={{ cursor: "auto" }}
                        src={EditIconDiabled}
                      />
                    </Tooltip>
                  </a>
                )}
                {!item.analysis_lock_flag ? (
                  <a>
                    <Tooltip title="View Data">
                      <img
                        style={{ marginLeft: 8 }}
                        alt={"View Data"}
                        src={ViewData}
                        onClick={() =>
                          handleViewDataClick(
                            item.analysis_id,
                            item.analysis_description,
                            item.analysis_name,
                            item.analysis_refresh_date,
                            userInfo.user_email === item.created_by_email_id
                          )
                        }
                      />
                    </Tooltip>
                  </a>
                ) : (
                  <a>
                    <Tooltip 
                    title={
                      (userInfo.user_email === item.created_by_email_id) &&
                      item.analysis_lock_flag ? (
                        <>
                          Explore and other functionalities are not active<br />
                          for reports created before 06/09/2024.
                        </>
                      ):"View Data"
                    } >
                      <img
                        style={{ marginLeft: 8, opacity: 0.5, cursor: "auto" }}
                        alt={"View Data"}
                        src={ViewData}
                      />
                    </Tooltip>
                  </a>
                )}

                {!item.analysis_lock_flag ? (
                  <a>
                    <Tooltip title="Copy">
                      <img
                        style={{ marginLeft: 8 }}
                        alt={"Copy"}
                        src={CopyIcon}
                        onClick={() =>
                          createCopyAnalyis(
                            item.analysis_id,
                            item.analysis_name
                          )
                        }
                      />
                    </Tooltip>
                  </a>
                ) : (
                  <a>
                    <Tooltip title={
                      (userInfo.user_email === item.created_by_email_id) &&
                      item.analysis_lock_flag ? (
                        <>
                          Explore and other functionalities are not active<br />
                          for reports created before 06/09/2024.
                        </>
                      ):"Copy"
                    }>
                      <img
                        style={{ marginLeft: 8, opacity: 0.5, cursor: "auto" }}
                        alt={"Copy"}
                        src={CopyIcon}
                      />
                    </Tooltip>
                  </a>
                )}

                <a>
                  <Tooltip title="Share">
                    <img
                      style={{ marginLeft: 8 }}
                      alt={"Share"}
                      src={ShareIcon}
                      onClick={() => shareModalOpen(true, item)}
                    />
                  </Tooltip>
                </a>
                {userInfo.user_email === item.created_by_email_id ? (
                  <a>
                    <Tooltip title="Delete">
                      <img
                        style={{ marginLeft: 8 }}
                        alt={"Delete"}
                        src={DeleteIcon}
                        onClick={() =>
                          showConfirm(item.analysis_id, item.analysis_name)
                        }
                      />
                    </Tooltip>
                  </a>
                ) : (
                  <a>
                    <img
                      style={{ marginLeft: 8, cursor: "auto" }}
                      alt={"Delete"}
                      src={DeleteDiabledIcon}
                    />
                  </a>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ padding: 12 }}>
            <Col span={12}>
              <div id="description-overflow" style={{ textAlign: "left" }}>
                <Tooltip title={item.analysis_description}>
                  <span className="card-item-text-description">
                    {item.analysis_description}
                  </span>
                </Tooltip>
              </div>
            </Col>
            <Col span={12}>
              <Row>
                {/* <Divider type="vertical" /> */}

                {/* style={{ width: "33%", float: "left" }} */}

                <div className="country-field">
                  {/* <div className="horizontal-border"> */}

                  <div className="globe-icon">
                    <img
                      alt={"Geography Icon"}
                      src={GeographyIcon}
                      style={{ verticalAlign: "bottom" }}
                    />
                  </div>
                  <div className="card-item-text">
                    {item.geography.slice(0, maxItems).map((item, index) => (
                      <Tooltip key={index} title={item}>
                        <React.Fragment key={index}>
                          {index > 0 && ", "}{" "}
                          {/* Add comma and space except for the first element */}
                          {item.length > 20
                            ? item.substring(0, 15) + "..."
                            : item}
                        </React.Fragment>
                      </Tooltip>
                    ))}
                    {item.geography.length - maxItems > 0 && (
                      <Tooltip
                        title={item.geography.slice(maxItems).join(", ")}
                      >
                        <Badge
                          title=""
                          count={`+${item.geography.length - maxItems}`}
                          showZero
                          color="#DEDCDE"
                          className="badge-class"
                        />
                      </Tooltip>
                    )}
                  </div>

                  {/* <span className="card-item-text">{item.geography}</span> */}
                  {/* </div> */}
                </div>

                <div className="country-field">
                  <div className="globe-icon">
                    <img
                      alt={"Dmographic Icon"}
                      src={DmographicIcon}
                      style={{ verticalAlign: "text-bottom" }}
                    />
                  </div>
                  <div className="card-item-text">
                    {item.demographics.slice(0, maxItems).map((item, index) => (
                      <Tooltip title={item} key={index}>
                        <React.Fragment key={index}>
                          {index > 0 && ", "}{" "}
                          {/* Add comma and space except for the first element */}
                          {item.length > 20
                            ? item.substring(0, 15) + "..."
                            : item}
                        </React.Fragment>
                      </Tooltip>
                    ))}
                    {item.demographics.length - maxItems > 0 && (
                      <Tooltip
                        title={item.demographics.slice(maxItems).join(", ")}
                      >
                        <Badge
                          title={""}
                          count={`+${item.demographics.length - maxItems}`}
                          showZero
                          color="#DEDCDE"
                          className="badge-class"
                        />
                      </Tooltip>
                    )}
                  </div>
                  {/* <span className="card-item-text">
                      {item.demographics}
                    </span> */}
                </div>
                <div className="country-field">
                  <div className="globe-icon">
                    <img
                      alt={"Use Case Icon"}
                      src={UseCaseIcon}
                      style={{ verticalAlign: "text-bottom" }}
                    />
                  </div>
                  <div className="card-item-text">
                    {item?.use_case?.slice(0, maxItems).map((item, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && ", "}{" "}
                        {/* Add comma and space except for the first element */}
                        {item}
                      </React.Fragment>
                    ))}
                    {item?.use_case?.length - maxItems > 0 && (
                      <Tooltip
                        title={item.use_case?.slice(maxItems).join(", ")}
                      >
                        <Badge
                          title={""}
                          count={`+${item.use_case?.length - maxItems}`}
                          showZero
                          color="#DEDCDE"
                          className="badge-class"
                        />
                      </Tooltip>
                    )}
                  </div>
                  {/* <span className="card-item-text">{item.use_case}</span> */}
                </div>
              </Row>
            </Col>
          </Row>
        </Card>
      ))}
      <div style={{ float: "right", marginBottom: 16 }}>
        <Pagination
          current={props.currentPageNo}
          pageSize={pageSize}
          total={props.totalCount}
          onChange={handleChangePage}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} Reports`
          }
          showSizeChanger={false}
        />
      </div>
      {shareFlag ? (
        <div>
          <ShareAnalysis
            visible={true}
            rowDetail={rowDetail}
            onClose={() => shareModalOpen(false)}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SharedTable;
