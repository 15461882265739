export const LIKERT_CHART = "Likert Scale";
export const RANK_ORDER = "Rank Order";
export const SINGLE_SELECT_A = "Single Select-A";
export const SINGLE_SELECT_B = "Single Select-B";
export const MULTIPLE_SELECT = "Multi Select";
export const OPEN_RESPONSE ="Open";
export const GRID_TYPE = "Grid";
export const MAX_DETAILS=10;
export const QUESTION_ID="ZS_STANDARD_012.6"

