import axios from "axios";
import axiosInterceptor from './AxiosInterceptor';

// export const BaseUrl = ENVIRONMENTS[SELECTED_ENVIRONMENT].baseurl || "";
export const baseUrl = process.env.REACT_APP_BASE_API_URL;
export const idmUrl = process.env.REACT_APP_IDM_URL;

axiosInterceptor();
export const redirectToIDM = async (e) => {
  if (e.response.status === 401 || e.response.status === 403) {
    window.location.replace(idmUrl);
  }
};

//to get geograpghy chart data (World Map)
export const getResponsesCategory = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/display-category-data`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    redirectToIDM(e);
  }
};

//to get questions by Category
export const getQuestionsByCategory = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/display-category-ques-data`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    redirectToIDM(e);
  }
};

//to set pin/unpin questions
export const setPinUnpinQuestions = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/explore-pin-question`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    redirectToIDM(e);
  }
};

//to get the Response Details
export const getResponseDetails = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/display-response-details`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    redirectToIDM(e);
  }
};

//to get the Response Reasons
export const getResponseReasons = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/display-response-reasons`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    redirectToIDM(e);
  }
};

//to get the Response Reasons
export const getResponseReasonsDropdown = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/display-response-details-dropdown`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    redirectToIDM(e);
  }
};
