import React from "react";
import "./MaintenancePage.scss";
import ZaidynLogo from "../../assets/icons/zaidyn-logo.svg";

const MaintenancePage = () => {
  return (
    <main class="maintenance-container">
      <header class="mx-auto text-center">
        <img
          class="mb-3 zaidyn-logo"
          src={ZaidynLogo}
          title="Zaidyn Logo"
          alt="Zaidyn Logo"
          draggable="false"
        />
        <h1 class="font-weight-light">
          SORRY! WE ARE UNDER SCHEDULED MAINTENANCE.
        </h1>
        <p className="maintain-label">
          Our portal is currently undergoing scheduled maintenance, We'll be
          right back.
        </p>
        <p className="maintain-label">Thank you for your patience.</p>
        <div class="py-4"></div>
        {/* <p>
          In case of any query reach out to us by
          <a href="mailto:sanofi.clinicalsupport@zs.com?subject=ZAIDYN%20Study%20Feasibility%20Query">
            clicking here.
          </a>
        </p> */}
      </header>
    </main>
  );
};

export default MaintenancePage;
