import axios from 'axios';
import { notification } from "antd";

const setupInterceptor = () => {
  axios.interceptors.request.use(
    async (config) => {
      try {
        const sessionid = sessionStorage.getItem("sessionId") || null;
        if (sessionid) {
          config.headers = {
            ...config.headers,
            'Session-ID': sessionid,
          };
        }

        return config;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  let isRedirectingNotificationDisplayed = false; // Flag to track whether the notification has been displayed

  axios.interceptors.response.use(
    (response) => {
      // Check if the response contains the specified error message
      if (response.data && response.data.message === "Invalid Session") {
        // Check if the notification has already been displayed
        if (!isRedirectingNotificationDisplayed) {
          isRedirectingNotificationDisplayed = true;
  
          //sessionStorage.setItem('loggedout','yes')
          // Redirect to the specified URL
          notification.info({
            message: 'Redirecting to IDM',
            description: 'You are being redirected to the IDM URL.',
          });
          
          const idmUrl = process.env.REACT_APP_IDM_URL;
          window.location.replace(idmUrl);
        }
  
        return Promise.reject(response); // Reject the promise to prevent the original request from continuing
      }

      return response;
    },
    (error) => {
      // console.log('Error in response interceptor:', error);
      return Promise.reject(error);
    }
  );
};

export default setupInterceptor;
