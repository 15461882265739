import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrop, useDrag } from "react-dnd";
import { Row, Col, Divider, Input, Modal } from "antd";
import Draggable from "../../assets/icons/draggable.svg";
import ResponseCard from "./ResponseCard";
import DeleteIcon from "../../assets/icons/delete.svg";
import {
  setFindingReportData,
  setFindingReportDeleteInsight,
  setFindingReportDeleteSupportingData,
  setFindingReportUpdateInsight,
  setFindingsReportAddDataToInsights,
  setFindingsReportMoveInsight,
} from "../../reducer/PEBCommonSlice";
import {
  deleteFindingsData,
  getAnalysisData,
  saveFindingsReportData,
} from "../../services/ExperienceBankAPI";
import AddSupportingInsights from  "../../components/add-supporting-insights-modal/AddSupportingInsights"
const { TextArea } = Input;

const InsightsList = ({
  insights,
  index,
  id,
  isResponseCardDragging,
  setIsResponseCardDragging,
  editAccessFlag,
  analysisData
}) => {
  const dispatch = useDispatch();
  const isFullWidth = useSelector(
    (state) => state.PEBCommon?.findingsReport.isFullWidth
  );
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail);
  const analysisId = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisId
  );
  const analysisLastData = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisData
  );

  const [dragFlag, setDragFlag] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(insights.insight_text);
  const ref = useRef(null);
  const insightsList = useSelector(
    (state) => state.PEBCommon?.findingsReport.insight_list
  );
  const ISCARDSELECTED = useSelector(
    (state) => state.PEBCommon.findingsReport.selectedCard
  );
  const unMappedSupportingData = useSelector(
    (state) => state.PEBCommon?.findingsReport.supporting_data
  );
  useEffect(() => {
    setText(insights.insight_text);
  }, [insights]);

  const [, drag] = useDrag(
    () => ({
      type: "insights",
      canDrag: editAccessFlag && dragFlag && !isEditing,

      item: () => ({
        index,
        insight_id: insights.insight_id,
        order: insights.insight_order,
        text: insights.insight_text,
      }),
    }),
    [dragFlag , isEditing]
  );

  const [, dropInsights] = useDrop(
    () => ({
      accept: "insights",
      drop: (item) => {
        if (item.index === index) return;
        const draggedIndex = item.index;
        const droppedIndex = index;

        // const droppedIndex = insightsList.findIndex(
        //   (insight) => insight.insight_id === id
        // );
        const dummyData = [...insightsList];

        const [dragedData] = dummyData.splice(draggedIndex, 1);
        dummyData.splice(droppedIndex, 0, dragedData);

        const payloadData = dummyData.map((item, index) => {
          return { [item.insight_id]: index };
        });

        const outputObject = Object.fromEntries(
          payloadData.map((item) => Object.entries(item)[0])
        );

        dispatch(setFindingsReportMoveInsight({ draggedIndex, droppedIndex }));
        updateInsightOrder(outputObject).then((data) =>
          getFindingsReportData()
        );
        // item.index = index;
      },
    }),
    [insightsList]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "response",
      // canDrop: editAccessFlag,

      drop(_item, monitor) {
        if (_item.insight_id === insights.insight_id) return;

        dispatch(
          setFindingReportDeleteSupportingData({
            index: _item.index,
            insight_id: _item.insight_id,
            supporting_data: _item.cardData,
          })
        );
        if (_item.insight_id) {
          moveCardToOtherInsight(
            _item.insight_id,
            _item.cardData.ques_id,
            _item.supp_card_id,
            _item.supp_card_order
          ).finally((data) => getFindingsReportData());
          dispatch(
            setFindingsReportAddDataToInsights({
              index,
              id,
              prev_id: _item.insight_id,
              data: _item.cardData,
              supp_card_id: _item.supp_card_id,
              question: _item.cardData.ques_text,
              reason: [..._item.reason],
              supp_card_order: _item.supp_card_order,
            })
          );
        } else {
          dispatch(
            setFindingsReportAddDataToInsights({
              index,
              data: _item.cardData,
              supp_card_id: _item.supp_card_id,
              question: _item.cardData.ques_text,
              reason: [..._item.reason],
              supp_card_order: _item.supp_card_order,
            })
          );
          let res = addSupportingDataToInsights(
            _item.cardData,
            _item.supp_card_id,
            _item.supp_card_order
          )
            .then((data) => {})
            .finally((data) => getFindingsReportData());
        }
        setIsResponseCardDragging(false);
      },
    }),
    [insights]
  );

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setDragFlag(false);
      updateInsightText(text).then((data) => console.log(data));
      dispatch(setFindingReportUpdateInsight({ index, data: text }));
      setIsEditing(false);
    }
  };
  const handleBlur = () => {
    setDragFlag(false);
    updateInsightText(text).then((data) => console.log(data));
    dispatch(setFindingReportUpdateInsight({ index, data: text }));
    setIsEditing(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setDragFlag(false);
        setIsEditing(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleRowClick = (event) => {
    if (!dragFlag) {
      setDragFlag(true);
      return;
    }

    if (dragFlag && !isEditing) {
      setIsEditing(true);
      return;
    }

    if (dragFlag && isEditing && event.target.tagName !== "INPUT") {
     // setIsEditing(false);
     // return;
    }
    //return;
  };

  const renderCard = (card, index) => (
    <ResponseCard
      key={index}
      editAccessFlag={editAccessFlag}
      supp_card_order={card.supp_card_order}
      supp_card_id={card.supp_card_id}
      index={index}
      id={index}
      reason={Array.isArray(card.reason) ? card.reason : [card.reason] || []}
      insight_id={insights.insight_id}
      sequence={card.sequence}
      questionData={index}
      unMappedSupportingData={card.supporting_data}
    />
  );

  const updateInsightOrder = async (data, save_type = "only_insight") => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: analysisId,
      save_type,
      order_data: data,
      prev_insight_id: "",
      edit_insight_flag: false,
      insight_id: id,
      insight_text: "",
      ques_id: "",
      insight_order: 1,
      ques_order: 0,
      supporting_data: "",
      preference: "",
      reason: "",
      supp_card_id: "",
      supp_card_order: 6,
    };
    let response = await saveFindingsReportData(payload);
    return response;
  };
  const getFindingsReportData = async (payload) => {
    let response = await getAnalysisData({
      user_id: userInfo.user_id,
      analysis_id: analysisId,
      screen_name: "findings",
      analysis_refresh_date:analysisLastData?.last_updated || "",      
    });
    let sequence = 1;
    const sortedInsightList = response.result.insight_mapped_data.sort(
      (a, b) => {
        const aValue = a.insight_sequence;
        const bValue = b.insight_sequence;
        return aValue - bValue;
      }
    );
    sortedInsightList.forEach((element) => {
      element.questions_list.forEach((question) => {
        question.sequence = sequence;
        sequence++;
      });
    });
    response.result.unmapped_data.forEach((element) => {
      element.sequence = sequence++;
    });

    dispatch(
      setFindingReportData({
        insight_list: sortedInsightList,
        supporting_data: response.result.unmapped_data,
      })
    );
    localStorage.setItem(
      "suppCardOrder",
      response.result.analysis_total_ques_count
    );
  };
  const updateInsightText = async (text) => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: analysisId,
      save_type: "only_insight",
      order_data: {},
      prev_insight_id: "",
      edit_insight_flag: true,
      insight_id: id,
      insight_text: text,
      ques_id: "",
      insight_order: 1,
      ques_order: 0,
      supporting_data: "",
      preference: "",
      reason: "",
      supp_card_id: "",
      supp_card_order: 6,
    };
    let response = await saveFindingsReportData(payload);
    return response;
  };

  const addSupportingDataToInsights = async (
    data,
    supp_card_id,
    supp_card_order
  ) => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: analysisId,
      save_type: "insight_with_supp_ques",
      order_data: {},
      prev_insight_id: "",
      insight_id: id,
      insight_text: "",
      ques_id: data.ques_id,
      insight_order: 1,
      ques_order: 0,
      supporting_data: JSON.stringify(data),
      preference: "",
      reason: "",
      supp_card_id: supp_card_id,
      supp_card_order: supp_card_order,
    };

    let response = await saveFindingsReportData(payload);
    return response;
  };

  drag(dropInsights(ref));

  const moveCardToOtherInsight = async (prev_id, ques_id, supp_card_id) => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: analysisId,
      save_type: "insight_with_supp_ques",
      order_data: {},
      prev_insight_id: prev_id,
      insight_id: id,
      insight_text: "",
      ques_id: ques_id,
      insight_order: insights.insight_order,
      ques_order: 0,
      supporting_data: "",
      preference: "",
      reason: "",
      supp_card_id,
      supp_card_order: 6,
    };

    let response = await saveFindingsReportData(payload);
    return response;
  };
  const deleteInsight = () => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: analysisId,
      delete_type: "only_insight",
      insight_id: id,
      ques_id: "",
      ques_order: "",
      insight_order: 0,
      supp_card_id: "",
      supp_card_order: 6,
    };
    deleteFindingsData(payload).finally(() => getFindingsReportData());
  };
  const deleteCard = (index) => {
    dispatch(setFindingReportDeleteInsight(index));
    deleteInsight();
  };

  const [addInsightFlag ,  setInsightFlag] = useState(false);
  const [insightDetails,setInsightDetails] = useState();
  const addInsightModalOpen = (state, row) => {
    setInsightFlag(state);
    setInsightDetails(row);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputRef = useRef(null);
  return (
    <>
      <Col className="insights-div" ref={ref} style={{ padding: 0 }}>
        {dragFlag && editAccessFlag && (
          <Col
            span={24}
            className="drag-header-insight"
            style={{ cursor: "move", opacity: isEditing ? 0.5 : 1 }}>
            <img src={Draggable} alt="Draggable" />
            <img
              src={DeleteIcon}
              alt="Delete Icon"
              style={{ cursor: "pointer" }}
              onClick={() => setIsModalOpen(true)}
            />
            <Modal
              zIndex={1004}
              okText="Delete"
              onOk={() => {
                setIsModalOpen(false);
                deleteCard(id);
              }}
              open={isModalOpen}
              width={350}
              onCancel={() => setIsModalOpen(false)}>
              <div
                style={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                }}>
                Delete the selected Insight along with all of its Supporting
                Data from this report?
              </div>
            </Modal>
          </Col>
        )}
        <Row
          align="middle"
          onClick={handleRowClick}
          className={
            dragFlag && editAccessFlag ? "question-drag" : "question-card"
          }
          justify="space-between"
          style={{ padding: 8, margin: 0 }}
          id={`question-Card${index}`}
          ref={drop}>
          <Col
            span={isFullWidth ? 11 : 24}
            className="abc"
            style={{ fontWeight: "600", opacity: 1 }}>
            {!isEditing || !editAccessFlag ? (
              <>{insights.insight_text}</>
            ) : editAccessFlag ? (
              <TextArea
                rows={4}
                // span={isFullWidth ? 11 : 24}
                className="abc"
                type="text"
                placeholder="Add a card..."
                value={text}
                onChange={handleChange}
                onBlur={() => handleBlur()}
                onKeyDown={handleKeyDown}
                autoFocus
                ref={inputRef}
              />
            ) : null}
          </Col>
          {isFullWidth && (
            <Divider
              span={2}
              type="vertical"
              style={{ margin: 0, opacity: isEditing ? 0.5 : 1 }}
            />
            // <Col span={2}>
            //   <Divider type="vertical" style={{ height: "100%" }} />
            // </Col>
          )}
          <Col
            span={isFullWidth ? 11 : 24}
            style={{ opacity: isEditing ? 0.5 : 1 }}>
            <Row gutter={[16, 16]} style={{ padding: "0 8px" }}>
              {insights.questions_list?.map((card, i) => (
                <Col
                  span={24}
                  className="response"
                  key={i}
                  style={{ padding: 0 }}>
                  {renderCard(card, i)}
                </Col>
              ))}
              {ISCARDSELECTED && (
                <Col
                  span={24}
                  className="unmapped_data"
                  onClick={() => addInsightModalOpen(true, insights)}>
                  <div style={{ margin: "6%", cursor: "pointer" }}>
                    Add a supporting insight by{" "}
                    <span style={{ color: "#27a6a4" }}>
                      selecting this area
                    </span>{" "}
                    or dragging the cards.
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      {addInsightFlag && (
        <div>
          <AddSupportingInsights
            visible={true}
            insightDetails={insightDetails}
            analysisData={analysisData}
            unMappedSupportingData={unMappedSupportingData}
            onClose={() => addInsightModalOpen(false)}
          />
        </div>
      )}
    </>
  );
};

export default InsightsList;
