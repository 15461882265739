export const TA_BAR_CHART_OPTIONS = {
  chart: {
    type: "bar",
    scrollablePlotArea: {
      minHeight: 400,
      opacity: 1,
    },
  },
  title: {
    text: "",
    align: "left",
    style: {
      fontSize: "16px",
    },
  },
  credits: { enabled: false },
  xAxis: {
    type: "category",
    labels: {
      style: {
        fontSize: "13px",
        fontFamily: "Verdana, sans-serif",
      },
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "Respondents",
      style: {
        color: "#666666",
        fontSize: "16px ",
      },
    },
    lineWidth: 1,
    tickAmount: 6,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [
    {
      name: "Respondents",
      data: [],
    },
  ],
};

export const GEO_BAR_CHART_OPTIONS = {
  chart: {
    type: "bar",
    scrollablePlotArea: {
      minHeight: 400,
      opacity: 1,
    },
  },
  title: {
    text: "",
    align: "left",
    style: {
      fontSize: "16px",
    },
  },
  credits: { enabled: false },
  xAxis: {
    type: "category",
    labels: {
      style: {
        fontSize: "13px",
        fontFamily: "Verdana, sans-serif",
      },
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "Respondents",
      style: {
        color: "#666666",
        fontSize: "16px ",
      },
    },
    lineWidth: 1,
    tickAmount: 6,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [
    {
      name: "Respondents",
      data: [],
    },
  ],
};

export const AGE_GENDER_CHART_OPTIONS = {
  chart: {
    type: "bar",
    scrollablePlotArea: {
      minHeight: 400,
      opacity: 1,
    },
  },
  title: {
    text: "",
    align: "left",
    style: {
      fontSize: "16px",
    },
  },
  credits: { enabled: false },
  xAxis: {
    type: "category",
    labels: {
      style: {
        fontSize: "13px",
        fontFamily: "Verdana, sans-serif",
      },
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "Respondents",
      style: {
        color: "#666666",
        fontSize: "16px ",
      },
    },
    lineWidth: 1,
    tickAmount: 6,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  colors: ["#6328B8"],
  series: [
    {
      name: "Respondent",
      data: [],
    },
  ],
};

export const GENDER_CHART_OPTIONS = {
  chart: {
    type: "bar",
    scrollablePlotArea: {
      minHeight: 400,
      opacity: 1,
    },
  },
  title: {
    text: "",
    align: "left",
    style: {
      fontSize: "16px",
    },
  },
  credits: { enabled: false },
  xAxis: {
    type: "category",
    labels: {
      style: {
        fontSize: "13px",
        fontFamily: "Verdana, sans-serif",
      },
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "Respondents",
      style: {
        color: "#666666",
        fontSize: "16px ",
      },
    },
    lineWidth: 1,
    tickAmount: 6,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  colors: ["#6328B8"],
  series: [
    {
      name: "Respondent",
      data: [],
    },
  ],
};

export const DEMOGRAPHICS_EX_US_CHART_OPTIONS = {
  chart: {
    type: "bar",
    scrollablePlotArea: {
      minHeight: 400,
      opacity: 1,
    },
  },
  title: {
    text: "",
    align: "left",
    style: {
      fontSize: "16px",
    },
  },
  credits: { enabled: false },
  xAxis: [
    {
      lineWidth: 0.5,
      tickWidth: 0,
      categories: "",
    },
    {
      labels: {
        formatter: "",
        useHTML: true,
        style: {
          fontSize: "14px",
          fontFamily: "Open Sans, sans-serif",
          width: "110px",
          margin: 10,
        },
      },
      linkedTo: 0,
      lineWidth: 0.2,
      tickWidth: 0,
      margin: 8,
    },
    {
      lineWidth: 1,
      offset: 0,
    },
    // {
    //   lineWidth: 0,
    //   tickWidth: 0,
    //   categories: "",
    // },
    // {
    //   type: "category",
    //   labels: {
    //     useHtml: true,
    //     style: {
    //       fontSize: "13px",
    //       fontFamily: "Verdana, sans-serif",
    //     },
    //   },
    // },
  ],
  yAxis: {
    min: 0,
    title: {
      text: "Respondents",
      style: {
        color: "#666666",
        fontSize: "16px ",
      },
    },
    lineWidth: 1,
    tickAmount: 6,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  colors: ["#6328B8"],
  series: [
    {
      name: "Respondent",
      data: [],
    },
  ],
};

export const INDICATION_CHART_OPTIONS = {
  chart: {
    type: "bar",
    scrollablePlotArea: {
      minHeight: 400,
      opacity: 1,
    },
  },
  title: {
    text: "By Demographics",
    align: "left",
    style: {
      fontSize: "16px",
    },
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      pointWidth: 30,
      dataLabels: {
        enabled: true,
      },
    },
  },
  xAxis: [
    {
      lineWidth: 0,
      tickWidth: 0,
      categories: "",
    },
    {
      labels: {
        useHtml: true,
        formatter: "",
        style: {
          fontSize: "14px",
          fontFamily: "Open Sans, sans-serif",
          width: "110px",
        },
      },
      lineWidth: 0.2,
      linkedTo: 0,
      tickWidth: 0,
      margin: 8,
    },
    {
      lineWidth: 1,
      offset: 0,
    },
  ],
  yAxis: [
    {
      title: {
        text: "Respondents",
        style: {
          color: "#666666",
          fontSize: "16px ",
        },
      },
      lineWidth: 1,
      tickAmount: 8,
      labels: {
        enabled: true,
      },
      max: 500,
    },
  ],
  colors: ["#6328B8"],
  series: [
    {
      name: "Respondent",
      data: [],
    },
  ],
};

export const SUB_INDICATION_CHART_OPTIONS = {
  chart: {
    type: "bar",
    scrollablePlotArea: {
      minHeight: 400,
      opacity: 1,
    },
  },
  title: {
    text: "By Demographics",
    align: "left",
    style: {
      fontSize: "16px",
    },
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      pointWidth: 30,
      dataLabels: {
        enabled: true,
      },
    },
  },
  xAxis: [
    {
      lineWidth: 0,
      tickWidth: 0,
      categories: "",
    },
    {
      labels: {
        useHtml: true,
        formatter: "",
        style: {
          fontSize: "14px",
          fontFamily: "Open Sans, sans-serif",
          width: "110px",
        },
      },
      lineWidth: 0.2,
      linkedTo: 0,
      tickWidth: 0,
      margin: 8,
    },
    {
      lineWidth: 1,
      offset: 0,
    },
  ],
  yAxis: [
    {
      title: {
        text: "Respondents",
        style: {
          color: "#666666",
          fontSize: "16px ",
        },
      },
      lineWidth: 1,
      tickAmount: 8,
      labels: {
        enabled: true,
      },
      max: 500,
    },
  ],
  colors: ["#6328B8"],
  series: [
    {
      name: "Respondent",
      data: [],
    },
  ],
};
