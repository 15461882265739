import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tag, Modal, Table, Checkbox } from "antd";
import "./AddSupportingInsights.scss";
import {
  saveFindingsReportData,
  getAnalysisData,
} from "../../services/ExperienceBankAPI";
import { setFindingReportData } from "../../reducer/PEBCommonSlice";
import { NotificationTypes } from "../../shared/Notification/Notification";
import { setNotificationData } from "../../reducer/PEBCommonSlice";

const AddSupportingInsights = ({
  visible,
  insightDetails,
  analysisData,
  unMappedSupportingData,
  onClose,
}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail);
  const analysisLastData = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisData
  );
  const [open, setOpen] = useState(visible);
  const [dataArray, setDataArray] = useState([]);
  const [selectedRowKys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    // {
    //   title: (
    //     <div style={{ width: 150 }}>Select</div>
    //   ),
    //   dataIndex: "selection",
    //   width: 150,
    //   render: (_, record) => (
    //     <Checkbox
    //       onChange={(e) => onSelectChange(e, record)}
    //       checked={selectedRowKys.includes(record.key)}
    //     />
    //   ),
    // },
    {
      title: "Supporting Insight",
      dataIndex: "question",
      width: "60%",
      render: (_, item, index) => (
        <>
          {index + 1}. {item.question}
        </>
      ),
    },
    {
      title: "Respondents",
      dataIndex: "criteriaDetails",
      render: (_, respondents) => (
        <>
          {respondents?.criteriaDetails.map((criteria) => (
            <div style={{ marginBottom: 4 }}>
              <Tag
                color={"blue"}
                key={respondents}
                style={{
                  display: "inline-flex",
                  height: 32,
                  alignItems: "center",
                }}>
                <strong style={{ marginRight: 5 }}>
                  {Object.values(criteria)}:
                </strong>
                {Object.keys(criteria)}
              </Tag>
              <br />
            </div>
          ))}
        </>
      ),
    },
  ];

  const supportingDataList = () => {
    const newArray = [];
    unMappedSupportingData.forEach((item, index) => {
      const criteriaDetails =
        item.supporting_data[0]?.criteria_details ||
        item.supporting_data.criteria_details;
      const formattedCriteria = [];

      criteriaDetails?.forEach((criteria) => {
        formattedCriteria.push({
          [criteria.criteria_name]: criteria.details[0].nSize,
        });
      });
      newArray.push({
        key: index + 1,
        analysisId: item?.analysis_id,
        quesId: item?.ques_id,
        suppCardDetails: { [item.supp_card_id]: item.supp_card_order },
        question:
          item?.supporting_data[0]?.ques_text || item.supporting_data.ques_text,
        criteriaDetails: [...formattedCriteria],
      });
    });

    setDataArray(newArray);
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const CustomTitle = () => <div style={{ width: 200 }}>Your Custom Title</div>;
  const rowSelection = {
    selectedRowKys,
    onChange: onSelectChange,
    hideSelectAll: true,
    // columnTitle: () => 'Select'
  };

  const handleOk = (e) => {
    if(selectedRows.length > 0){
     saveSupportingInsights();
    }
    setOpen(false);
    onClose();
  };
  const handleCancel = () => {
    setOpen(false);
    onClose();
  };

  // ------------------------- API call -------------------------

  const saveSupportingInsights = async () => {
    const supportingCardsData = selectedRows.map(
      (item) => item.suppCardDetails
    );
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: analysisData.id,
      insight_id: insightDetails.insight_id,
      save_type: "insight_with_supp_ques",
      insight_text: "",
      multiple_cards_data: supportingCardsData || [],
      save_multiple_flag: true,
      order_data: "",
      prev_insight_id: "",
      edit_insight_flag: false,
      ques_id: "",
      insight_order: 0,
      ques_order: "",
      supporting_data: "",
      preference: "",
      reason: "",
      supp_card_id: "",
      supp_card_order: 0,
    };

    let response = await saveFindingsReportData(payload);
    // displayFindings();

    if (response?.status === "SUCCEEDED") {
      displayFindings();
      dispatch(
        setNotificationData({
          type: NotificationTypes.SUCCESS,
          message: "Supporting insights added successfully",
        })
      );
    } else {
      dispatch(
        setNotificationData({
          type: NotificationTypes.ERROR,
          message: "Something went wrong Please try again.",
        })
      );
    }

    return response;
  };

  const displayFindings = async () => {
    let response = await getAnalysisData({
      user_id: userInfo.user_id,
      analysis_id: analysisData.id,
      screen_name: "findings",
      analysis_refresh_date: analysisLastData?.last_updated || "",
    });

    let sequence = 1;
    const sortedInsightList = response.result.insight_mapped_data.sort(
      (a, b) => {
        const aValue = a.insight_sequence;
        const bValue = b.insight_sequence;
        return aValue - bValue;
      }
    );
    sortedInsightList.forEach((element) => {
      element.questions_list.forEach((question) => {
        question.sequence = sequence;
        sequence++;
      });
    });
    response.result.unmapped_data.forEach((element) => {
      element.sequence = sequence++;
    });

    dispatch(
      setFindingReportData({
        insight_list: sortedInsightList,
        supporting_data: response.result.unmapped_data,
      })
    );
    localStorage.setItem(
      "suppCardOrder",
      response.result.analysis_total_ques_count
    );
  };

  useEffect(() => {
    supportingDataList();
  }, []);

  return (
    <div className="addmodal">
      <Modal
        zIndex={1004}
        title="Select Supporting Insight"
        centered
        open={open}
        okText={"Add Supporting Insight to Comment"}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: unMappedSupportingData.length === 0,
        }}
        width={900}
        className="abcd">
        {/* <div>titlt</div> */}
        <div style={{ paddingBottom: 10, paddingTop: 30, width: "85%" }}>
          <strong>Comment: </strong>
          <span>{insightDetails.insight_text}</span>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataArray}
          pagination={false}
          scroll={{
            y: 250,
          }}
          style={{ paddingBottom: 20, paddingTop: 20 }}
          className="custom-table"
        />
      </Modal>
    </div>
  );
};
export default AddSupportingInsights;
