export const PEBInitialState = {
  userDetail: {},
  filterAPIData: {},
  navigationTab: "/explore",
  filterApplyData: {},
  exploreFilterApplyData: [],
  searchTextExplore: "",
  notificationData: {},
  disableData: false,
  deleteCardRefresh: {},
  filterData:{},
  recordData:null,
  supportingData:[],
  hasAccessToReport: true,
  findingsReport: {
    isOpen: false,
    isFullWidth: false,
    analysisId: null,
    insight_list: [],
    supporting_data: [],
    selectedCard: "",
    analysisData: {
      name: "",
      description: "",
    },
    supportingDataMap: {},
  },
  tableParam: {
    pagination: {
      current: 1,
      total: 50,
    },
  },
  refreshAnalysisList: "",
  highlightCardData: {},
  analysisLockFlag: false,
};
