import axios from "axios";
import axiosInterceptor from './AxiosInterceptor';

// export const BaseUrl = ENVIRONMENTS[SELECTED_ENVIRONMENT].baseurl || "";
export const baseUrl = process.env.REACT_APP_BASE_API_URL;
export const idmUrl = process.env.REACT_APP_IDM_URL;

axiosInterceptor();
export const redirectToIDM = async (e) => {
  if (e.response.status === 401 || e.response.status === 403) {
    window.location.replace(idmUrl);
  }
};

//to get geograpghy chart data (World Map)
export const getSurveyQuestions = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/survey-questions-library`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    redirectToIDM(e);
  }
};