import React, { useEffect, useRef } from "react";
import "./FindingsDrawer.scss";
import {
  Layout,
  Dropdown,
  Button,
  Divider,
  Input,
  Spin,
  Collapse,
  Card,
} from "antd";
import { Tooltip } from "antd";
import Draggable from "../../assets/icons/draggable.svg";
import { setExploreFilterApplyData } from "../../reducer/PEBCommonSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import FilterIcon from "../../assets/icons/zs-icon-arrow-down.svg";
import ShrinkIcon from "../../assets/icons/Shrink.svg";
import CloseIcon from "../../assets/icons/Close.svg";
import html2canvas from "html2canvas";
import GearIcon from "../../assets/icons/PEB-icon-frame-setting.svg";
import ExpandIcon from "../../assets/icons/icon.svg";
import DownloadIcon from "../../assets/icons/zs-icon-download.svg";
import DownloadDisabledIcon from "../../assets/icons/zs-icon-download-disabled.svg";
import ViewEyeIcon from "../../assets/icons/view-eye-icon.svg";
import ClosedEyeIcon from "../../assets/icons/closed-eye-icon.svg";
import ExcelIcon from "../../assets/icons/excel-icon.svg";
import DocumentIcon from "../../assets/icons/document-icon.svg";
import GearEditIcon from "../../assets/icons/gear-edit-icon.svg";
import EditIcon from "../../assets/icons/edit-pencil-icon.svg";
import aesEncryt from "../../shared/AesEncryt/AesEncryt";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setNavigationTab,
  setIsFindingsReportFullWidth,
  setIsFindingsReportIsOpen,
  setFindingReportAddInsight,
  setFindingsReportAnalysisId,
  setFindingReportData,
  setFindingsReportAnalysisData,
  setHasAccessToReport,
  setDisableData,
  setAnalysisLockFlag
} from "../../reducer/PEBCommonSlice";
import ResponseCard from "./ResponseCard";
import InsightsList from "./InsightsList";
import {
  downloadScreenshots,
  getAnalysisData,
  saveFindingsReportData,
} from "../../services/ExperienceBankAPI";
import { useNavigate } from "react-router-dom";

import FindingReportSettingsModal from "../finding-report-setting-modal/FindingReportSettingsModal";
import { useDrop } from "react-dnd";
const { Content } = Layout;
const { TextArea } = Input;

const FindingsDrawer = ({
  isClosable,
  isShrinkable,
  isExpandable,
  fullWidth,
}) => {
  const ins = useSelector(
    (state) => state.PEBCommon?.findingsReport.insight_list
  );

  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details
  const analysisId = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisId
  );
  const unMappedSupportingData = useSelector(
    (state) => state.PEBCommon?.findingsReport.supporting_data
  );

  const [editFlag, setEditFlag] = useState(false);

  const [analysisData, setAnalysisData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const analysisLastData = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisData
  );
  const [addInsights, setAddInsights] = useState(false);
  const dispatch = useDispatch();
  const isFullWidth = useSelector(
    (state) => state.PEBCommon?.findingsReport.isFullWidth
  );
  const filterAppliedData = useSelector(
    (state) => state.PEBCommon?.exploreFilterApplyData
  );
  const [filtersList, setFiltersList] = useState();
  const [activeKeys, setActiveKeys] = useState([]);
  const [cardInput, setCardInput] = useState("");
  const analysisLockFlagStatus = useSelector((state)=> state.PEBCommon?.analysisLockFlag);
  const getFindingsReportData = async (payload) => {
    getAnalysisData({
      user_id: userInfo.user_id,
      analysis_id: analysisId || "",
      screen_name: "findings",
      analysis_refresh_date: analysisLastData?.last_updated || "",
    }).then((response) => {
      dispatch(setExploreFilterApplyData(response.result.filters_data));
      dispatch(setDisableData(response.result.edit_access_flag));
      dispatch(setAnalysisLockFlag(response.result.analysis_lock_flag));
      const sortedInsightList = response.result.insight_mapped_data.sort(
        (a, b) => {
          const aValue = a.insight_sequence;
          const bValue = b.insight_sequence;
          return aValue - bValue;
        }
      );
      let sequence = 1;
      sortedInsightList.forEach((element) => {
        element.questions_list.forEach((question) => {
          question.sequence = sequence;
          sequence++;
        });
      });
      response.result.unmapped_data.forEach((element) => {
        element.sequence = sequence++;
      });

      dispatch(
        setFindingsReportAnalysisData({
          id: analysisId,
          name: response.result.analysis_name,
          description: response.result.analysis_description,
          last_updated: response.result.analysis_refresh_date,
          quesMaxUpdatedTs: response.result.ques_max_updated_ts,
        })
      );
      // dispatch(setHasAccessToReport(response.result.edit_access_flag));

      setAnalysisData({
        name: response.result.analysis_name,
        description: response.result.analysis_description,
        id: analysisId,
      });
      setEditFlag(response.result.edit_access_flag);

      localStorage.setItem(
        "suppCardOrder",
        response.result.analysis_total_ques_count
      );

      dispatch(
        setFindingReportData({
          insight_list: sortedInsightList,
          supporting_data: response.result.unmapped_data,
        })
      );
    });
  };
  useEffect(() => {
    getFindingsReportData();
  }, [isOpen,analysisId]);

  const panelStyle = {
    marginBottom: 8,
    borderRadius: "2px",
    border: "1px solid #2F9BFF",
  };

  const convertAgeFormat =(ageItem)=>{
   return ageItem.map(group => `${group.age_label} (${group.age_value[0]} years-${group.age_value[1]} years)`)
    .join(', ');
  }

  const convertPrimaryOtherIndication = (data) => {
    let values = [];

    data.forEach((item) => {
      // Add therapeutic_area
      if (item.therapeutic_area) {
        values.push(item.therapeutic_area);
      }

      // Add indications and sub_indications
      item.indication_node.forEach((indication) => {
        if (indication.indication) {
          values.push(indication.indication);
        }
        indication.sub_indication.forEach((subIndication) => {
          values.push(subIndication);
        });
      });
    });

    return values.join(", ");
  };

  useEffect(() => {
    let objList = [];
    filterAppliedData?.forEach((item, index) => {
      const obj = {
        key: index + 1,
        style: panelStyle,
        label: (
          <div>
            <strong style={{ color: "#0952CA" }}>
              {item.no_of_respondents}:{" "}
            </strong>
            <span className="catDescp">{item.comparator_name}</span>
          </div>
        ),
        children: (
          <Row className="row-col" style={{ background: "#F8FCFF" }}>
            {!item.is_empty_comparator && (
              <Col span={24} style={{ marginTop: 8 }}>
                <strong className="cf-label3">Definition</strong>
              </Col>
            )}
            {item.primary_indication?.length > 0 && !analysisLockFlagStatus && (
              <Col span={24}>
                <strong className="cf-label">Primary Indication: </strong>
                <span className="cf-label2">
                  {convertPrimaryOtherIndication(item.primary_indication)}
                </span>
              </Col>
            )}
               {item.other_indication?.length > 0 && !analysisLockFlagStatus && (
              <Col span={24}>
                <strong className="cf-label">Other Indication: </strong>
                <span className="cf-label2">
                  {convertPrimaryOtherIndication(item.other_indication)} 
                </span>
              </Col>
            )}
            {item.therapeutic_area.length > 0 && analysisLockFlagStatus && (
              <Col span={24}>
                <strong className="cf-label">Therapy Area: </strong>
                <span className="cf-label2">
                  {item.therapeutic_area.toString()}
                </span>
              </Col>
            )}
            {item.indication.length > 0 && analysisLockFlagStatus && (
              <Col span={24}>
                <strong className="cf-label">Indication: </strong>
                <span className="cf-label2">{item.indication.toString()}</span>
              </Col>
            )}
            {item.sub_indication.length > 0 && analysisLockFlagStatus && (
              <Col span={24}>
                <strong className="cf-label">Sub-Indication: </strong>
                <span className="cf-label2">
                  {item.sub_indication.toString()}
                </span>
              </Col>
            )}
            {item.geography.length > 0 && (
              <Col span={24}>
                <strong className="cf-label">Geography: </strong>
                <span className="cf-label2">{item.geography.toString()}</span>
              </Col>
            )}
            {item.race.length > 0 && (
              <Col span={24}>
                <strong className="cf-label">Race: </strong>
                <span className="cf-label2">{item.race.toString()}</span>
              </Col>
            )}
            {item.ethnicity.length > 0 && (
              <Col span={24}>
                <strong className="cf-label">Ethnicity: </strong>
                <span className="cf-label2">{item.ethnicity.toString()}</span>
              </Col>
            )}
            {item.origin_country.length > 0 && (
              <Col span={24}>
                <strong className="cf-label">Country of Origin: </strong>
                <span className="cf-label2">
                  {item.origin_country.toString()}
                </span>
              </Col>
            )}
            {item.age.length > 0 && (
              <Col span={24}>
                <strong className="cf-label">Age: </strong>
                <span className="cf-label2">{convertAgeFormat(item.age)}</span>
              </Col>
            )}
            {item.gender.length > 0 && (
              
              <Col span={24}>
                <strong className="cf-label">Sex at Birth: </strong>
                <span className="cf-label2">{item.gender.toString()}</span>
              </Col>
            )}
          </Row>
        ),
      };
      objList.push(obj);
    });
    setFiltersList(objList);
  }, [filterAppliedData]);

  const onChange = (key) => {
    setActiveKeys(key);
  };

  const handleInputChange = (e) => {
    setCardInput(e.target.value);
  };

  const addInsight = async (data) => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: analysisId,
      save_type: "only_insight",
      order_data: {},
      prev_insight_id: "",
      insight_id: "",
      insight_text: data.insight_text,
      ques_id: "",
      insight_order: data.insight_order,
      ques_order: 10,
      supporting_data: "",
      preference: "",
      reason: "",
      supp_card_id: "",
      supp_card_order: 6,
    };
    let response = await saveFindingsReportData(payload);
    return response.result;
  };
  const handleAddCard = () => {
    if (cardInput.trim() !== "") {
      const new_insight = {
        insight_order: ins.length + 1,

        questions_list: [],
        analysis_name: "analysis_nm_465",
        insight_text: cardInput || "",
        insight_id: "", // Initialize it as an empty string
      };

      // Call the addInsight function to save the insight
      addInsight(new_insight)
        .then((response) => {
          setAddInsights(false);
          new_insight.insight_id = response.insight_id;
          dispatch(setFindingReportAddInsight(new_insight));
        })
        .catch((error) => {
          ///useNotifications
        })
        .finally(() => setCardInput(""));

      // Dispatch the updated new_insight

      // Reset the cardInput
    }
    if (cardInput.trim() == "") setAddInsights(false);
  };
//
  const downloadWordDocument = () => {
    if (isFullWidth) {
      takeScreenshot();
    } else {
      dispatch(setIsFindingsReportFullWidth(true));
      setTimeout(() => {
        takeScreenshot();
      }, 1000);
    }
  };

  const [flag, setFlag] = useState(false);

  const takeScreenshot = async () => {
    setFlag(true);

    const fetchImageBase64 = async (elementId) => {
      return new Promise((resolve, reject) => {
        const element = document.getElementById(elementId);
        if (element) {
          html2canvas(element).then((canvas) => {
            canvas.toBlob((blob) => {
              const reader = new FileReader();
              reader.onloadend = function () {
                const base64data = reader.result;
                resolve(base64data);
              };
              reader.readAsDataURL(blob);
            }, "image/png");
          });
        } else {
          reject("Element not found");
        }
      });
    };

    // Simulate an asynchronous operation with a timeout
    const download = async () => {
      try {
        const insightsDataPromises = ins.map((item, index) =>
          fetchImageBase64("question-Card" + index)
        );
        const supportingQuesPromises = unMappedSupportingData.map(
          (item, index) => fetchImageBase64(`${item.supp_card_id}`)
        );

        const insightsDataImages = await Promise.all(insightsDataPromises);
        const supportingQuesImages = await Promise.all(supportingQuesPromises);

        const insightsDataList = insightsDataImages.map(
          (image) => image.split("data:image/png;base64,")[1]
        );
        const supportingQuesList = supportingQuesImages.map(
          (image) => image.split("data:image/png;base64,")[1]
        );

        const pocPayload = {
          user_id: userInfo.user_id,
          screen_name: "finding",
          analysis_id: analysisId,
          insights_data_list: insightsDataList,
          supporting_ques_list: supportingQuesList,
          export_type: "word",
          filters_data: filterAppliedData,
          analysis_lock_flag: analysisLockFlagStatus,
        };
        // Make an API call to send the pocPayload
        //sessionStorage.setItem('loggedout','yes')
        const response = await downloadScreenshots(pocPayload);
        window.open(aesEncryt(response?.export_url), "_parent");
        //sessionStorage.setItem('loggedout','no')
        setFlag(false);
      } catch (error) {
        console.error("Error:", error);
        setFlag(false);
      }
    };
    download();
  };

  const downloadExcelDocument = async () => {
    try {
      const pocPayload = {
        user_id: userInfo.user_id,
        screen_name: "finding",
        analysis_id: analysisId,
        insights_data_list: [],
        supporting_ques_list: [],
        export_type: "excel",
        filters_data: filterAppliedData,
        analysis_lock_flag: analysisLockFlagStatus,
      };
      const response = await downloadScreenshots(pocPayload);
      window.open(aesEncryt(response.export_url), "_parent");
    } catch (error) {}
  };

  const closeFindingsReport = (flag) => {
    dispatch(setIsFindingsReportIsOpen(false));
    dispatch(setIsFindingsReportFullWidth(false));
    dispatch(setFindingReportData({ insight_list: [], supporting_data: [] }));
    dispatch(setFindingsReportAnalysisId(null));
    dispatch(setFindingsReportAnalysisData({}));
    dispatch(setHasAccessToReport(true));
    dispatch(setDisableData(false));
    dispatch(setAnalysisLockFlag(false));
    if (flag) navigate("/analysis-library");
    else {
      navigate("/explore");
    }
    localStorage.removeItem("suppCardOrder");
    localStorage.removeItem("analysisId");
  };
  const openAddFindingsModal = () => {
    // dispatch(setFindingsReportAddToFindingsModal(true))
    setIsOpen(true);
  };
  const viewAllFindings = () => {
    // dispatch(setIsFindingsReportIsOpen(false));
    closeFindingsReport(true);
    dispatch(setNavigationTab("/analysis-library"));
  };

  const items = [
    {
      label: "Edit Settings",
      key: "edit-setting",
      onClick: openAddFindingsModal,
    },
    // {
    //   label: "View All Findings Report",
    //   key: "view-all",
    //   onClick: viewAllFindings,
    // },
    {
      label: "Close Report",
      key: "close-report",
      onClick: () => closeFindingsReport(false),
    },
  ];
  const items2 = [
    {
      label: editFlag ? "Edit Settings" : "View Settings",
      key: "edit-setting",
      onClick: openAddFindingsModal,
    },
    {
      label: "View All Findings Report",
      key: "view-all",
      onClick: viewAllFindings,
    },
  ];
  const downloadOptions = [
    {
      label: "Download Excel Document",
      key: "word-document",
      onClick: downloadExcelDocument,
      icon: (
        <img
          src={ExcelIcon}
          alt="open-icon"
          style={{ cursor: "pointer", marginRight: 5 }}
        />
      ),
    },
    {
      label: "Download Word Document from full screen mode",
      key: "excel-document",
      onClick: downloadWordDocument,
      icon: (
        <img
          src={DocumentIcon}
          alt="open-icon"
          style={{ cursor: "pointer", marginRight: 5 }}
        />
      ),
    },
  ];
  const antIcon = <LoadingOutlined spin />;

  const renderCard = (card, index) => {
    if (!card) return;

    const cards = card.supporting_data;
    return (
      <ResponseCard
        editAccessFlag={editFlag}
        supp_card_order={card.supp_card_order}
        supp_card_id={card.supp_card_id}
        key={card.ques_id}
        index={index}
        id={cards.ques_id}
        sequence={card.sequence}
        // moveCard = {moveCard}
        reason={Array.isArray(card.reason) ? card.reason : [card.reason] || []}
        ques_order={card.ques_order}
        setIsResponseCardDragging={setIsResponseCardDragging}
        isResponseCardDragging={isResponseCardDragging}
        unMappedSupportingData={cards}
      />
    );
  };
  const [isResponseCardDragging, setIsResponseCardDragging] = useState(false);

  const renderInsights = (insights, index) => {
    return (
      <InsightsList
        editAccessFlag={editFlag}
        insights={insights}
        key={index}
        id={insights.insight_id}
        index={index}
        dragFlag={isResponseCardDragging}
        setIsResponseCardDragging={setIsResponseCardDraggingFunction}
        isResponseCardDragging={isResponseCardDragging}
        analysisData={analysisData}
      />
    );
  };
  useEffect(() => {}, []);
  const setIsResponseCardDraggingFunction = (value) => {
    setIsResponseCardDragging(value);
  };
  const inputRef = useRef();

  const containerRef = useRef();
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // setIsActive(false);
      setAddInsights(false);
      handleAddCard();
    }
  };
  const handleBlur = () => {
    handleAddCard();
  };
  const [, drop] = useDrop(
    () => ({
      accept: "response",
      // canDrop: editAccessFlag,

      drop: (_item) => {
        if (!_item.insight_id) return;
        addSupportingDataToUnmappedData(
          _item.insight_id,
          _item.supp_card_id
        ).then(() => getFindingsReportData());
      },
    }),
    [ins]
  );
  const addSupportingDataToUnmappedData = async (
    insight_id,
    supp_card_id,
    supp_card_order
  ) => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: analysisId,
      save_type: "supp_ques",
      order_data: {},
      prev_insight_id: "",
      insight_id: insight_id,
      insight_text: "",
      ques_id: "",
      insight_order: 1,
      ques_order: 0,
      supporting_data: "",
      move_from_insight_to_unmapped: true,
      preference: "",
      reason: "",
      supp_card_id: supp_card_id,
      supp_card_order: 0,
    };

    let response = await saveFindingsReportData(payload);
    return response;
  };
  const ISCARDSELECTED = useSelector(
    (state) => state.PEBCommon.findingsReport.selectedCard
  );
  const supportingCardArea = useRef(null);
  drop(supportingCardArea);

  const goTOExplore = () => {
    dispatch(setIsFindingsReportFullWidth(false));
    navigate("/explore", { state: { tabValue: "2" } });
  };

  return (
    <Layout
      className="your-component"
      style={
        !isFullWidth
          ? {
              // maxHeight: "100vh",
              // minHeight: "100vh",
              overflowY: "auto",
              position: "sticky",
              top: 56, // Stick the header to the top of the viewport
              width: "100%",
              height: "calc(100vh - 56px)",
              // marginBottom: 10,
            }
          : {
              top: 0,
              position: "sticky",
              overflowY: "auto",
              height: "calc(100vh - 56px)",
              // bottom: 10,
            }
      }>
      {isOpen && (
        <FindingReportSettingsModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          analysisID={analysisId}
          addFlag={false}
        />
      )}
      <Spin indicator={antIcon} spinning={flag}>
        <div
          className="row"
          style={{ position: "sticky", top: 0, zIndex: 1000 }}>
          <h1 style={{ width: "fit-content" }}>{analysisData.name}</h1>
          {!isClosable ? (
            isExpandable ? (
              <Dropdown
                menu={{ items: items, selectable: false }}
                trigger={["click"]}
                overlayStyle={{ width: 200, borderRadius: "0px" }}>
                <img
                  src={FilterIcon}
                  alt="filter-icon"
                  style={{ cursor: "pointer" }}
                />
              </Dropdown>
            ) : (
              <>
                <img
                  style={{
                    cursor: "pointer",
                  }}
                  src={GearEditIcon}
                  alt="download-icon"
                  onClick={() => {
                    openAddFindingsModal();
                  }}
                />
                <img
                  style={{
                    cursor: "pointer",
                  }}
                  src={EditIcon}
                  alt="edit-icon"
                  onClick={() => {
                    // setFullWidth(false);
                    dispatch(setIsFindingsReportFullWidth(false));
                  }}
                />
              </>
            )
          ) : (
            <>
              <img
                style={{
                  cursor: "pointer",
                }}
                src={GearEditIcon}
                alt="download-icon"
                onClick={() => {
                  openAddFindingsModal();
                }}
              />
              {analysisLockFlagStatus ? (
                <img
                  alt={"Edit Disabled"}
                  style={{ cursor: "auto" , opacity: '0.5' }}
                  src={EditIcon}
                />
              ) : (
                <img
                  style={{
                    cursor: "pointer",
                  }}
                  src={EditIcon}
                  alt="edit-icon"
                  onClick={() => {
                    // setFullWidth(false);
                    goTOExplore();
                  }}
                />
              )}
            </>
          )}
          <Dropdown
            menu={{ items: downloadOptions }}
            trigger={["click"]}
            overlayStyle={{ width: 240, borderRadius: "0px" }}>
            <img
              style={{
                cursor: "pointer",
              }}
              src={DownloadIcon}
              alt="download-icon"
            />
          </Dropdown>
          {isExpandable && (
            <img
              src={ExpandIcon}
              style={{ cursor: "pointer" }}
              alt="expand-icon"
              onClick={() => {
                // setFullWidth(true);
                dispatch(setIsFindingsReportFullWidth(true));
              }}
            />
          )}
          {!isExpandable && (
            <img
              src={CloseIcon}
              style={{ cursor: "pointer", marginLeft: "auto" }}
              alt="close-icon"
              onClick={() => {
                closeFindingsReport(isClosable ? true : false);
              }}
            />
          )}
        </div>
        <Content
          style={{
            textAlign: "left",
            padding: "24px",
            background: "#FAFAFA",
          }}
          ref={containerRef}>
          <Row gutter={[16, 16]}>
            <>
              <Col span={24}>
                <strong>Description: </strong>
                {analysisData.description}
              </Col>

              <Col className="target-population-style" span={24}>
                <Collapse
                  activeKey={activeKeys}
                  collapsible="header"
                  onChange={onChange}
                  items={[
                    {
                      key: "1",
                      label: (
                        <div>
                          <strong>{filterAppliedData?.length} </strong>
                          <span className="catDesc">
                            Target Populations in Report
                          </span>
                          {isFullWidth ? (
                            activeKeys.length === 0 ? (
                              <span className="view-close-label">
                                <Divider
                                  type="vertical"
                                  style={{ height: "2em", marginLeft: 24 }}
                                />
                                <img
                                  src={ViewEyeIcon}
                                  alt="open-icon"
                                  style={{ cursor: "pointer", marginRight: 5 }}
                                />
                                View Definitions
                              </span>
                            ) : (
                              <span className="view-close-label">
                                <Divider
                                  type="vertical"
                                  style={{ height: "2em", marginLeft: 24 }}
                                />
                                <img
                                  src={ClosedEyeIcon}
                                  alt="close-icon"
                                  style={{ cursor: "pointer", marginRight: 5 }}
                                />
                                Close Definitions
                              </span>
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      ),
                      children: (
                        <>
                          {!isFullWidth ? (
                            <div
                              className="div-collapse"
                              style={{ padding: 8 }}>
                              <Collapse
                                items={filtersList}
                                collapsible="header"></Collapse>
                            </div>
                          ) : (
                            <Row gutter={16} style={{ padding: 10 }}>
                              {filtersList
                                ? filtersList?.map((item) => (
                                    <Col
                                      className="trgt-pop-fullscreen"
                                      span={8}>
                                      <Card title={item.label} bordered={false}>
                                        {item.children}
                                      </Card>
                                    </Col>
                                  ))
                                : ""}
                            </Row>
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              </Col>
              {editFlag && (
                <Col span={24}>
                  <Button
                    onClick={() => {
                      setAddInsights(true);
                    }}>
                    + Add Comment
                  </Button>
                </Col>
              )}
            </>

            {addInsights && editFlag && (
              <Col span={24} className="">
                <Col
                  span={24}
                  className="drag-header"
                  gutter={[8, 8]}
                  style={{ background: "#DEDCDE", cursor: "move" }}>
                  <img src={Draggable} alt="draggable" />
                </Col>
                <Col
                  span={24}
                  className={" question-card question-drag-add "}
                  style={{ padding: 12 }}>
                  <TextArea
                    rows={4}
                    type="text"
                    autoFocus
                    value={cardInput}
                    onChange={handleInputChange}
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    onBlur={() => {
                      handleBlur();
                    }}
                  />
                  {/* <Col span={24} align="right" style={{ margin: 16 }}>
                    {" "}
                    <Button
                      onClick={() => setAddInsights(false)}
                      style={{ marginRight: 16 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      // className="analysis-btn"
                      onClick={() => handleAddCard()}
                    >
                      OK{" "}
                    </Button>
                  </Col> */}
                </Col>
              </Col>
            )}

            <Col span={24}>
              {ins.map((insight, index) => (
                <React.Fragment key={index}>
                  {" "}
                  {renderInsights(insight, index)}
                </React.Fragment>
              ))}
            </Col>
            <Divider style={{ margin: "4px", border: "1px solid #9C9AA1" }} />

            <Row
              gutter={[16, 16]}
              align="top"
              ref={supportingCardArea}
              style={{ width: "100%" }}>
              {ISCARDSELECTED && (
                <Col
                  span={24}
                  style={{ padding: 16 }}
                  className="unmapped_data">
                  Drag in supporting Area
                </Col>
              )}

              {unMappedSupportingData.length > 0 &&
                unMappedSupportingData.map((card, i) => (
                  <Col
                    span={isFullWidth ? 12 : 24}
                    key={i}
                    style={{
                      padding: "0 8px",
                      // display: "flex",
                    }}
                    className="response">
                    {renderCard(card, i)}
                  </Col>
                ))}
            </Row>
          </Row>
        </Content>
      </Spin>
    </Layout>
  );
};

export default FindingsDrawer;
