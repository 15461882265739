import { Modal, Input, Form, Row, Col, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import "./FindingReportSettingsModal";
import {
  setFindingsReportAddSupportingData,
  setFindingsReportAnalysisId,
  setHasAccessToReport,
  setIsFindingsReportFullWidth,
  setIsFindingsReportIsOpen,
  setNotificationData,
  setFindingsReportAnalysisData
} from "../../reducer/PEBCommonSlice";
import { useDispatch, useSelector } from "react-redux";
import { NotificationTypes } from "../../shared/Notification/Notification";
import {
  createFindingsReport,
  getFindingsReportSettings,
  getFindingsSettingsDropdownData,
  isReportNameUnique,
  saveFindingsReportData,
} from "../../services/ExperienceBankAPI";
import "./FindingReportSettingsModal.scss";
const FindingReportSettingsModal = ({
  isOpen,
  setIsOpen,
  recordData,
  filterData,
  supporting_data,
  isEditable = true,
  analysisID,
  addFlag = true,
  type,
  createAddFlag = false, 
}) => {
  const modalTitle = (
    <Row>
      <Col flex="auto" style={{ fontSize: "16px" }}>
        Patient Insights Report Details
      </Col>
      <Col flex="125px" style={{ fontWeight: "normal" }}>
        <span style={{ color: "red" }}>* </span>Required Field
      </Col>
    </Row>
  );
  const [savingInProgress, setSavingInProgress] = useState(false);
  const analysisData = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisData
  );
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleFormChange = (changedFields) => {
    const therapeuticAreaChanged =
      changedFields.hasOwnProperty("therapeutic_area");

    const indicationChanged = changedFields.hasOwnProperty("indication");
    if (therapeuticAreaChanged) {
      setIsIndicationVisible(true);
      setIsSubIndicationVisible(false);
    } else if (indicationChanged) {
      setIsSubIndicationVisible(true);
    }

    if (therapeuticAreaChanged && changedFields.therapeutic_area == undefined) {
      setIsSubIndicationVisible(false);
      // form.setFieldValue("sub_indication", []);
      setIsIndicationVisible(false);
      // form.setFieldValue("indication", []);
    } else if (indicationChanged && changedFields.indication == undefined) {
      setIsSubIndicationVisible(false);
    }
  };
  useEffect(() => {
    form.resetFields();
  }, []);

  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details

  const [initialFormValues, setInitialFormValues] = useState({});
  const [duplicateReportNameMessage, setDuplicateReportNameMessage] =
    useState("");
  const [reportName, setReportName] = useState(true);
  const dispatch = useDispatch();
  const [dropdownOptions, setDropdownOptions] = useState({
    therapeutic_area: [],
    use_case: [],
    indication: [],
    sub_indication: [],
  });
  const [loading, setLoading] = useState(true);
  const [editFlag, setEditFlag] = useState(true);

  useEffect(() => {
    if (analysisID) {
      const getData = () => {
        let res = getFindingsReportSettings({
          user_id: userInfo.user_id,
          analysis_id: analysisID,
        });
        res.then((res) => {
          setLoading(false);

          setEditFlag(res.data.edit_access_flag);

          setInitialFormValues({
            report_name: res.data.analysis_name,
            report_description: res.data.analysis_description,
            program: res.data.program,
            study: res.data.study,
            therapeutic_area: res.data.therapeutic_area,
            use_case: res.data.use_case,
            indication: res.data.indication,
            sub_indication: res.data.sub_indication,
            geography: res.data.geography,
            demographics: res.data.demographics,
          });
          // setInitialFormValues()
        });
      };
      getData();
    } else {
      setLoading(false);

      setInitialFormValues({
        report_name: "",
        report_description: "",
        program: "",
        study: "",
        therapeutic_area: [],
        use_case: [],
        indication: [],
        sub_indication: [],
        geography: "",
        demographics: "",
      });
    }
  }, []);

  const dropdownToggleHandler = (event, dropdown_column) => {
    if (
      dropdown_column === "indication" &&
      form.getFieldValue("therapeutic_area")?.length === 0
    ) {
      return;
    }

    if (
      dropdown_column === "sub_indication" &&
      form.getFieldValue("indication")?.length === 0
    ) {
      return;
    }

    const getDropdownData = async () => {
      const therapeutic_areaValue = form.getFieldValue("therapeutic_area");
      const indicationValue = form.getFieldValue("indication");

      const sub_indicationValue = form.getFieldValue("sub_indication");
      const use_case = form.getFieldValue("use_case");
      const payload = {
        user_id: userInfo.user_id,
        dropdown_column,
        analysis_id: "",
        use_case: use_case,
        therapeutic_area: therapeutic_areaValue,
        indication: indicationValue,
        sub_indication: sub_indicationValue,
      };

      if (dropdown_column === "therapeutic_area") {
        payload.indication = [];
        payload.sub_indication = [];
      } else if (dropdown_column === "indication") {
        payload.sub_indication = [];
      }
      payload[dropdown_column] = [];

      let response = await getFindingsSettingsDropdownData(payload);

      const newOptions = {
        ...dropdownOptions,
        [dropdown_column]: response?.total_count
          ? response.list_data?.map((data) => ({
              label: data.value,
              value: data.value,
            }))
          : [],
      };

      setDropdownOptions(newOptions);
    };

    if (event) {
      getDropdownData();
    }
  };

  const saveCreatedAnalysis = async (values) => {
    const therapeutic_areaValue = values["therapeutic_area"];
    const indicationValue = values["indication"];
    const sub_indicationValue = values["sub_indication"];
    const use_case = values["use_case"];

    const payload = {
      user_id: userInfo.user_id,
      screen_name: "modal",
      opened_analysis_id: localStorage.getItem("analysisId") || "",
      comparator_data:
        filterData?.length > 0
          ? filterData
          : [
              {
                age: null,
                race: null,
                gender: null,
                ethnicity: null,
                geography: null,
                indication: null,
                comparator_nm: "",
                origin_country: null,
                sub_indication: null,
                therapeutic_area: null,
              },
            ],
      // comparator_data: [],
      analysis_id: analysisID ? analysisID : "",
      analysis_refresh_date: analysisData?.last_updated || "",
      analysis_name: values["report_name"],
      analysis_description: values["report_description"],
      use_case: use_case,
      therapeutic_area: therapeutic_areaValue,
      indication: indicationValue,
      sub_indication: sub_indicationValue,
      study: values["study"],
      program: values["program"],
    };

    let response = await createFindingsReport(payload);

    return response;
  };
  const addSupportingData = async (data, id, preference, reason) => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: id,
      save_type: "supp_ques",
      order_data: {},
      prev_insight_id: "",
      insight_id: "",
      insight_text: "",
      ques_id: !data?.ques_id ? "" : data?.ques_id,
      insight_order: 2,
      edit_insight_flag: false,
      ques_order: 10,
      insight_ques_order: 0,
      supporting_data: JSON.stringify(data.supporting_data),
      preference,
      reason,
      supp_card_id: "",
      supp_card_order: 1,
      added_from: type,
    };
    let response = await saveFindingsReportData(payload);

    return response;
  };
  const submitForm = async (values) => {
    setIsOpen(false);
    setConfirmLoading(false);

    form.resetFields();

    return saveCreatedAnalysis(values)
      .then(({ status, result, message }) => {
        setConfirmLoading(false);

        if (status === "SUCCEEDED") {
          dispatch(
            setNotificationData({
              type: NotificationTypes.SUCCESS,
              message,
            })
          );
          dispatch(setFindingsReportAnalysisId(result.analysis_id));
          dispatch(
            setFindingsReportAnalysisData({
              last_updated : result.analysis_refresh_date
            })
          );
          localStorage.setItem("analysisId", result.analysis_id);
          dispatch(setIsFindingsReportIsOpen(true));
          dispatch(setHasAccessToReport(true));
          dispatch(setIsFindingsReportFullWidth(false));
          if (addFlag) {
            dispatch(setFindingsReportAnalysisId(result.analysis_id));
            dispatch(
              setFindingsReportAnalysisData({
                last_updated : result.analysis_refresh_date
              })
            );
            localStorage.setItem("analysisId", result.analysis_id);
            // let finalData = ;
            addSupportingData(
              supporting_data,
              result.analysis_id,
              supporting_data?.preference || "",
              supporting_data?.reason || ""
            ).then((data) => {
              localStorage.setItem(
                "suppCardOrder",
                data.result.max_supp_card_order
              );
              dispatch(
                setFindingsReportAddSupportingData({
                  data: {
                    ...supporting_data,
                    supp_card_id: data.result.supp_card_id,
                    supp_card_order: 1,
                    preference: supporting_data.preference,
                    question: supporting_data.question,
                    reason: [supporting_data.reason],
                    respondents: supporting_data.respondents,
                  },
                  preference: supporting_data.preference,
                  question: supporting_data.question,
                  reason: [supporting_data.reason],
                  respondents: supporting_data.respondents,
                })
              );
            });
            dispatch(setIsFindingsReportIsOpen(true));
            dispatch(setHasAccessToReport(true));
            dispatch(setIsFindingsReportFullWidth(false));
          }
        }
      })
      .catch((err) => {
        dispatch(
          setNotificationData({
            type: NotificationTypes.ERROR,
            message: `Analysis  Failed to create`,
          })
        );
      });
  };
  const getUniqueName = async () => {
    let payload = {
      user_id: userInfo.user_id,
      screen_name: "report modal",
      analysis_name: form.getFieldValue("report_name"),
    };
    const response = await isReportNameUnique(payload);
    return response;
  };
  const [isIndicationVisible, setIsIndicationVisible] = useState(false);
  const [isSubIndicationVisible, setIsSubIndicationVisible] = useState(false);

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    virtual: false,
    placeholder: "Please Select",
    maxTagCount: "responsive",
    allowClear: true,
  };

  const handleOk = () => {
    setConfirmLoading(true);
    if (addFlag || initialFormValues.report_name != form.getFieldValue("report_name")) {
      getUniqueName().then((data) => {
        if (data.result) {
          form.submit();
          setConfirmLoading(false);
        } else {
          setConfirmLoading(false);
          setDuplicateReportNameMessage(data.message);
          setReportName(data.result);
        }
      });
    } else {
      form.submit();
    }
  };

  if (loading) return <></>;
  return (
    <Modal
      title={modalTitle}
      centered
      open={isOpen}
      width={600}
      zIndex={1004}
      onOk={handleOk}
      okText="Save"
      confirmLoading={confirmLoading}
      onCancel={() => {
        form.resetFields();
        setIsOpen(false);
      }}
      okButtonProps={{ disabled: !editFlag }}
    >
      <Row>
        <Col span={24}>
          {!reportName && (
            <Text type="danger">{duplicateReportNameMessage}</Text>
          )}
        </Col>
      </Row>
      <Form
        name="Modal"
        layout="vertical"
        form={form}
        initialValues={initialFormValues}
        onValuesChange={handleFormChange}
        onFinish={submitForm}
        style={{ marginTop: "30px" }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Report Name"
              name="report_name"
              rules={[{ required: true }]}
            >
              {editFlag ? (
                <Input.TextArea
                  showCount
                  size="large"
                  maxLength={40}
                  style={{
                    height: 41,
                    resize: "none",
                    borderRadius: "0px",
                  }}
                />
              ) : (
                <Col>{initialFormValues.report_name}</Col>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Description"
              name="report_description"
              rules={[{ required: true }]}
            >
              {editFlag ? (
                <Input.TextArea
                  showCount
                  // value={findingsReportDescription}
                  maxLength={100}
                  // onChange={(e) => { setFindingsReportDescription(e.target.value) }}
                  style={{ borderRadius: "0px" }}
                  placeholder="Findings Report Description"
                />
              ) : (
                <Col>{initialFormValues.report_description}</Col>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Program" name="program">
              {editFlag ? (
                <Input size="large" />
              ) : (
                <Col>{initialFormValues.program}</Col>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Study" name="study">
              {editFlag ? (
                <Input size="large" />
              ) : (
                <Col>{initialFormValues.study}</Col>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Therapy Area" name="therapeutic_area">
              {editFlag ? (
                <Select
                  {...selectProps}
                  onDropdownVisibleChange={(event) =>
                    dropdownToggleHandler(event, "therapeutic_area")
                  }
                  options={dropdownOptions.therapeutic_area}
                />
              ) : (
                <Col>{initialFormValues.therapeutic_area}</Col>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Use-Case" name="use_case">
              {editFlag ? (
                <Select
                  {...selectProps}
                  onDropdownVisibleChange={(event) =>
                    dropdownToggleHandler(event, "use_case")
                  }
                  options={dropdownOptions.use_case}
                />
              ) : (
                <Col>{initialFormValues.use_case}</Col>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          {editFlag ? (
            <Col span={12}>
              <Form.Item label="Indication" name="indication">
                {isIndicationVisible ||
                initialFormValues.indication.length > 0 ? (
                  <Select
                    {...selectProps}
                    onDropdownVisibleChange={(event) =>
                      dropdownToggleHandler(event, "indication")
                    }
                    options={dropdownOptions.indication}
                  />
                ) : null}
              </Form.Item>
            </Col>
          ) : (
            <Col span={12}>
              <Form.Item label="Indication" name="indication">
                <Col>{initialFormValues.indication}</Col>
              </Form.Item>
            </Col>
          )}

          {editFlag ? (
            <Col span={12}>
              <Form.Item label="Sub-Indication" name="sub_indication">
                {isSubIndicationVisible ||
                initialFormValues.sub_indication.length > 0 ? (
                  <Select
                    {...selectProps}
                    onDropdownVisibleChange={(event) =>
                      dropdownToggleHandler(event, "sub_indication")
                    }
                    options={dropdownOptions.sub_indication}
                  />
                ) : null}
              </Form.Item>
            </Col>
          ) : (
            <Col span={12}>
              <Form.Item label="Sub-Indication" name="sub_indication">
                <Col>{initialFormValues.sub_indication}</Col>
              </Form.Item>
            </Col>
          )}
        </Row>
        {!addFlag && !createAddFlag && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Geography" name="geography">
                <Col>{initialFormValues.geography}</Col>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Demographics" name="demographics">
                <Col>
                  {initialFormValues.demographics ?? "All Demographics"}
                </Col>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default FindingReportSettingsModal;
