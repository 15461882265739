import CryptoJS from 'crypto-js'

const aesEncryt = (cipherText) => {
   
     const key = CryptoJS.enc.Base64.parse('fEn7E4XYhApG7Deimjhdv+66z7o4ZJIQdwO+o0GLUsk=');
    const dataBytes = CryptoJS.enc.Base64.parse(cipherText);
    const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
    const ciphertext = CryptoJS.lib.WordArray.create(dataBytes.words.slice(4));
    const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
    const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
    return decryptedText
   
};

export default aesEncryt;
