import "./App.scss";
import Home from "./components/Home";
import { ConfigProvider } from "antd";
import AppRoutes from "./App.routes";
function App() {
  // localStorage.removeItem("analysisId")
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#27A6A4",
          colorInfo: "#FF9900",
         colorSuccess: "#FF9900",
          colorFillSecondary: "#ffffff",
          fontFamily:"Open Sans"
        },
      }}
    >
      <div className="App">
        <Home />
      </div>
    </ConfigProvider>
  );
}

export default App;
