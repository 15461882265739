import React, { useState } from "react";
import { Button, Modal } from "antd";

const HelpCenter = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Help Center"
        // open={isModalOpen}
        open={false}
        onOk={handleOk}
        footer={[
          <Button type="primary" onClick={handleOk}>
            Close
          </Button>,
        ]}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  );
};

export default HelpCenter;
