import { configureStore } from "@reduxjs/toolkit";
import PEBCommonSlice  from "./reducer/PEBCommonSlice";

 const store = configureStore({
  reducer: {
    // EICData: EICDataReducer,
    PEBCommon: PEBCommonSlice,
  },
});

export default store ;