import { notification } from 'antd';
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./Notifications.scss"
const Notification = () => {
    const [api, contextHolder] = notification.useNotification()
    const notificationData = useSelector((state) => state.PEBCommon?.notificationData);

    const openNotificationWithIcon = ({ message,placement, type, duration = 4.5, onClose = null }) => {
        if (type) {
            api[type]({
                message,
                duration: duration,
                onClose: onClose,
                placement: placement?placement:"topRight",
            });
        }
    };
    useEffect(() => {
        openNotificationWithIcon(notificationData)
    }, [notificationData])
    return (
        <>{contextHolder}</>
    );
};
export const NotificationTypes = {
    SUCCESS : "success" ,
    ERROR : "error" , 
    WARNING : "warning" ,
    INFO : "info"
}

export default Notification;