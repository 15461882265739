import axios from "axios";
import axiosInterceptor from './AxiosInterceptor';
import ConcurrentSession from '../components/concurrent-session/ConcurrentSessionModal'

export const baseUrl = process.env.REACT_APP_BASE_API_URL;
export const idmUrl = process.env.REACT_APP_IDM_URL;

axiosInterceptor();

export const apiWithoutInterceptor = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  withCredentials: true,
});

//Login API
export const getUserInfo = async () => {
 const new_session_request= JSON.parse(sessionStorage.getItem("new_session_request")) || false
  let payload = {
    user_id: "",
    password: "",
    new_session_request,
  };
  try {
    const { data } = await axios.post(`${baseUrl}/login`, payload, {
      withCredentials: true,
    });
    sessionStorage.setItem("new_session_request",JSON.stringify(false));
    return data;
  } catch (e) {
    
    // window.location.replace(idmUrl);
    if (e.response?.status === 401 || e.response?.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get list of analysis for landing screen
export const getAnalysisLibrary = async (payload) => {
  try {
    const { data } = await axios.post(`${baseUrl}/analysis-library`, payload, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};
export const logout = async (payload) => {
  try {
    const { data } = await axios.post(`${baseUrl}/logout`, payload, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get all the filter Data
export const getDisplayFilterData = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/display-user-filter-data`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get all the Users Email Ids
export const getUsersEmail = async (payload) => {
  try {
    const { data } = await axios.post(`${baseUrl}/get-users-email`, payload, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to Share Analysis to the other users
export const ShareAnalysisToUser = async (payload) => {
  try {
    const { data } = await axios.post(`${baseUrl}/share-analysis`, payload, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

//to get filter dropdown values/list
export const getFilterDropdownDataAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/filter-dropdown-data`,
      payload,
      {
        withCredentials: true,
      }
    );
    
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }

  }
};

//to save filter data
export const saveFilterDataAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/save-user-filter-data`,
      payload,
      {
        withCredentials: true,
      }
    );
   
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};
export const getAnalysisData = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/display-analysis-screen`,
      payload,
      {
        withCredentials: true,
      }
    );
   
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
   
  }
};
export const getFindingsReportSettings = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/display-analysis-settings`,
      payload,
      {
        withCredentials: true,
      }
    );
    
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }

  }
};
export const getFindingsSettingsDropdownData = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/finding-settings-dropdown`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

export const createFindingsReport = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/save-created-analysis`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }

  }
};

export const saveFindingsReportData = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/save-findings-data`,
      payload,
      {
        withCredentials: true,
      }
    );
  
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
   
  }
};

export const deleteCreatedAnalysis = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/delete-created-analysis`,
      payload,
      {
        withCredentials: true,
      }
    );
   
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
   
  }
};

export const copyAnalysis = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/copy-created-analysis`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};

export const downloadScreenshots = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/download-findings-report`,
      payload,
      {
        withCredentials: true,
      }
    );
   
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  
  }
};

export const deleteFindingsData = async (payload) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/delete-findings-data`,
      payload,
      {
        withCredentials: true,
      }
    );
    
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }

  }
};

export const isReportNameUnique = async (payload) => {
  try {
    const { data } = await axios.post(`${baseUrl}/get-all-analysis`, payload, {
      withCredentials: true,
    });
    
    return data;
  } catch (e) {
    
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  
  }
};

