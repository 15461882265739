import React, { useState, useRef, useEffect } from "react";
import { Button, Tag, Modal } from "antd";
import { DownOutlined, UpOutlined, LoadingOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useDrag, useDrop } from "react-dnd";
import NeutralIcon from "../../assets/icons/Neutral-icon.svg";
import AgreeIcon from "../../assets/icons/Agree-blue-thumb.svg";
import Disagree from "../../assets/icons/Disagree-orange-thumb.svg";
import Draggable from "../../assets/icons/draggable.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import HighlightIcon from "../../assets/icons/report-highlight-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeleteCardRefresh,
  setFindingReportData,
  setFindingReportDeleteSupportingData,
  setFindingsReportDragFlag,
  setFindingsReportMoveSupportingCard,
  setFindingsReportMoveSupportingDataInsideInsights,
  setHighlightCardData,
  setSelectedCardId,
} from "../../reducer/PEBCommonSlice";
import {
  deleteFindingsData,
  getAnalysisData,
  saveFindingsReportData,
} from "../../services/ExperienceBankAPI";

const ResponseCard = (props) => {
  const {
    id,
    index,
    item,
    ques_order,
    insight_id,
    editAccessFlag,
    supp_card_id,
    reason,
    supp_card_order,
    unMappedSupportingData: supporting_Data,
    sequence,
  } = props;

  const [flag, setShowAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ref = useRef(null);

  const dispatch = useDispatch();
  const insightsList = useSelector(
    (state) => state.PEBCommon?.findingsReport.insight_list
  );
  const unMappedSupportingData = useSelector(
    (state) => state.PEBCommon?.findingsReport.supporting_data
  );
  const isFullWidth = useSelector(
    (state) => state.PEBCommon?.findingsReport.isFullWidth
  );
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail);
  const analysisId = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisId
  );
  const analysisLastData = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisData
  );

  const cardData = Array.isArray(supporting_Data)
    ? supporting_Data[0]
    : supporting_Data;
  const ISCARDSELECTED = useSelector(
    (state) => state.PEBCommon.findingsReport.selectedCard
  );

  const [{ isDragging }, drag, preview] = useDrag({
    type: "response",
    canDrag: editAccessFlag && ISCARDSELECTED === supp_card_id,
    item: () => {
      return {
        id,
        index,
        item,
        cardData,
        insight_id,
        ques_order,
        supp_card_id,
        supp_card_order,
        reason,
      };
    },
    preview: {
      opacity: 1, // Adjust opacity  needed
      // Other custom styles
    },
  });
  const [{ handlerId }, drop] = useDrop({
    accept: "response",
    drop: (item) => {
      if (item.index == index) return;
      if (insight_id && item.insight_id === insight_id) {
        const draggedIndex = item.index;
        const droppedIndex = index;

        const dummyData = insightsList
          .filter((item) => item.insight_id === insight_id)
          .flatMap((item) => item.questions_list);

        const [dragedData] = dummyData.splice(draggedIndex, 1);
        dummyData.splice(droppedIndex, 0, dragedData);

        const payloadData = dummyData.map((item, index) => {
          return { [item.supp_card_id]: index };
        });

        const outputObject = Object.fromEntries(
          payloadData.map((item) => Object.entries(item)[0])
        );

        dispatch(
          setFindingsReportMoveSupportingDataInsideInsights({
            draggedIndex,
            droppedIndex,
            insight_id,
          })
        );
        updateSupportingDataOrder(
          outputObject,
          "insight_with_supp_ques",
          insight_id
        ).finally((data) => {
          getFindingsReportData();
        });
        return;
      }
      const draggedIndex = item.index;
      const droppedIndex = index;
      dispatch(
        setFindingsReportMoveSupportingCard({ draggedIndex, droppedIndex })
      );

      const dummyData = [...unMappedSupportingData];

      const [dragedData] = dummyData.splice(draggedIndex, 1);
      dummyData.splice(droppedIndex, 0, dragedData);

      const payloadData = dummyData.map((item, index) => {
        return { [item?.supp_card_id]: index };
      });

      const outputObject = Object.fromEntries(
        payloadData.map((item) => Object.entries(item)[0])
      );

      updateSupportingDataOrder(outputObject, "supp_ques").finally((data) => {
        getFindingsReportData();
      });
      item.index = index;
    },
    // canDrop: editAccessFlag,
  });
  const updateSupportingDataOrder = async (data, save_type) => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: analysisId,
      save_type,
      order_data: data,
      prev_insight_id: "",
      edit_insight_flag: false,
      insight_id: insight_id || "",

      insight_text: "",
      ques_id: "",
      insight_order: 1,
      ques_order: 0,
      supporting_data: "",
      preference: "",
      reason: "",
      supp_card_id: "",
      supp_card_order: 6,
    };
    let response = await saveFindingsReportData(payload);
    return response;
  };
  const getFindingsReportData = async () => {
    let response = await getAnalysisData({
      user_id: userInfo.user_id,
      analysis_id: analysisId,
      screen_name: "findings",
      analysis_refresh_date:analysisLastData?.last_updated || "",   
    });
    let sequence = 1;
    const sortedInsightList = response.result.insight_mapped_data.sort(
      (a, b) => {
        const aValue = a.insight_sequence;
        const bValue = b.insight_sequence;
        return aValue - bValue;
      }
    );
    sortedInsightList.forEach((element) => {
      element.questions_list.forEach((question) => {
        question.sequence = sequence;
        sequence++;
      });
    });
    response.result.unmapped_data.forEach((element) => {
      element.sequence = sequence++;
    });

    dispatch(
      setFindingReportData({
        insight_list: sortedInsightList,
        supporting_data: response.result.unmapped_data,
      })
    );
    localStorage.setItem(
      "suppCardOrder",
      response.result.analysis_total_ques_count
    );
  };

  const deleteCard = (index) => {
    dispatch(
      setFindingReportDeleteSupportingData({
        index,
        insight_id,
        supporting_data: cardData,
      })
    );
  };

  // const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  const handleViewMoreClick = (event) => {
    event.stopPropagation();
    setShowAll((flag) => !flag);
  };

  const opacity = isDragging ? 0 : 1;

  const deleteSupportingData = (ques_id, insight_id = null) => {
    const payload = {
      user_id: userInfo.user_id,
      analysis_id: analysisId,
      delete_type: !insight_id ? "supp_ques" : "insight_with_supp_ques",
      insight_id: insight_id || "",
      ques_id: cardData.ques_id,
      ques_order: ques_order,
      insight_order: 0,
      supp_card_order: 1,
      supp_card_id: supp_card_id,
    };
    deleteFindingsData(payload).finally((data) => {
      dispatch(setDeleteCardRefresh(cardData));
      dispatch(setSelectedCardId(""));
      getFindingsReportData();
      let order = localStorage.getItem("suppCardOrder");
      localStorage.setItem("suppCardOrder", order - 1);
    });
  };
  const getOrdinal = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const suffixIndex = number > 3 ? 0 : number;
    return number + suffixes[suffixIndex];
  };

  const handleClickOutside = (event) => {
    dispatch(setSelectedCardId(""));

    event.preventDefault();
    event.stopPropagation();
  };

  const handleRowClick = (event) => {
    if (!editAccessFlag) {
      return;
    }
    if (ISCARDSELECTED === "") dispatch(setSelectedCardId(supp_card_id));
    else {
      dispatch(setSelectedCardId(""));
      if (supp_card_id != ISCARDSELECTED)
        dispatch(setSelectedCardId(supp_card_id));
    }
    return;
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const highlightRow = (value) => {
    dispatch(setHighlightCardData(value));
  };
  const antIcon = <LoadingOutlined spin />;

  if (!cardData) return;
  if (!unMappedSupportingData) return;
  return (
    <Col ref={ref} style={{ opacity, zIndex: 30 }} span={24}>
      {/* {sequences} */}

      {ISCARDSELECTED === supp_card_id && editAccessFlag && (
        <Row
          className="drag-header"
          // gutter={[8, 8]}
          onClick={(event) => event.stopPropagation()}
          style={{ background: "#D0EAEA", cursor: "move", padding: 8 }}>
          <img src={Draggable} alt="Draggable icon" />
          <div>
            {!isFullWidth && (
              <img
                src={HighlightIcon}
                alt="Highlight Report"
                style={{ cursor: "pointer", marginRight: 8 }}
                onClick={(event) => {
                  highlightRow(cardData);
                  event.stopPropagation();
                }}
              />
            )}
            <img
              src={DeleteIcon}
              alt="Delete Icon"
              style={{ cursor: "pointer" }}
              onClick={() => setIsModalOpen(true)}
            />
          </div>

          <Modal
            onOk={(event) => {
              event.stopPropagation();
              deleteCard(index);
              deleteSupportingData(cardData.ques_id, insight_id);
              setIsModalOpen(false);
            }}
            zIndex={1004}
            open={isModalOpen}
            width={350} // Set the width to your desired value
            onCancel={() => setIsModalOpen(false)}>
            <div
              style={{
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0em",
              }}>
              Delete the selected Supporting Data from your report?
            </div>
          </Modal>
        </Row>
      )}
      <Row
        // ref={preview}
        id={`${supp_card_id}`}
        onClick={(event) => {
          event.stopPropagation();
          handleRowClick(event);
        }}
        // gutter={[8, 8]}
        className={
          ISCARDSELECTED === supp_card_id ? "question-drag" : "question"
        }
        style={{ padding: 8 }}
        data-handler-id={handlerId}>
        {cardData?.response_type === "" ||
        cardData?.responseType === "" ||
        cardData?.responseType === "" ? (
          <Col span={24}>
            <Row>
              {/* {supp_card_order}.{"  "} */}
              {sequence ?? supp_card_order}.{"  "}
              {isFullWidth
                ? cardData?.ques_text
                : cardData?.ques_text.length > 25 && !flag
                ? cardData?.ques_text.substring(0, 40) + "..."
                : cardData?.ques_text}
            </Row>

            {cardData?.criteria_details
              .slice(
                0,
                flag || isFullWidth ? cardData?.criteria_details?.length : 5
              )
              .map((data, index) => (
                <Row align="middle" style={{ padding: 8 }} key={index}>
                  <div
                    style={
                      isFullWidth || flag
                        ? {}
                        : { display: "flex", alignItems: "center" }
                    }>
                    <Col>
                      <Tag
                        color={"blue"}
                        style={{
                          padding: "2px 10px",
                          borderRadius: 2,
                          width: "auto",
                          wordBreak: (flag || isFullWidth) && "break-all",
                          whiteSpace: (flag || isFullWidth) && "normal",
                        }}>
                        {isFullWidth
                          ? data.criteria_name
                          : data.criteria_name.length > 12 && !flag
                          ? data.criteria_name.substring(0, 12) + "..."
                          : data.criteria_name}{" "}
                        : <strong>{data?.details[0].nSize}</strong>
                      </Tag>
                    </Col>
                    <Col>
                      <Row gutter={[8, 8]} align="start">
                        <Col style={{ marginRight: 2 }}>
                          <strong style={{ paddingRight: 2 }}>
                            {" "}
                            {data.details[2].value}%
                          </strong>
                          <span
                            style={{
                              paddingRight: 4,
                              fontWeight: 600,
                              color: "grey",
                            }}>
                            ({data.details[2].hover_string})
                          </span>
                          <img
                            alt={"Agree Icon"}
                            src={AgreeIcon}
                            style={{ verticalAlign: "text-bottom" }}
                          />{" "}
                        </Col>
                        <Col style={{ marginRight: 2 }}>
                          <strong style={{ paddingRight: 4 }}>
                            {" "}
                            {data.details[0].value}%
                          </strong>
                          <span
                            style={{
                              paddingRight: 4,
                              fontWeight: 600,
                              color: "grey",
                            }}>
                            ({data.details[0].hover_string})
                          </span>
                          {/* <span> {data.details[1].value}%</span> */}
                          <img
                            alt={"Disagree Icon"}
                            src={Disagree}
                            style={{ verticalAlign: "text-bottom" }}
                          />{" "}
                        </Col>
                        {/* {data.details?.[2]?.value && ( */}
                        <Col>
                          <strong style={{ paddingRight: 2 }}>
                            {" "}
                            {data.details[1]?.value}%
                          </strong>
                          <span
                            style={{
                              paddingRight: 4,
                              fontWeight: 600,
                              color: "grey",
                            }}>
                            ({data.details[1].hover_string})
                          </span>
                          <img
                            alt={"Neutral Icon"}
                            src={NeutralIcon}
                            style={{ verticalAlign: "text-bottom" }}
                          />{" "}
                        </Col>
                        {/* )} */}
                      </Row>
                    </Col>
                  </div>
                </Row>
              ))}
            {!isFullWidth && (
              <Col span={24}>
                <Button
                  type="link"
                  onClick={handleViewMoreClick}
                  style={{ color: "#716E79", padding: 0 }}>
                  {!flag ? (
                    <DownOutlined style={{ fontSize: "12px" }} />
                  ) : (
                    <UpOutlined style={{ fontSize: "12px" }} />
                  )}
                  {flag ? "View Less" : "View More"}
                </Button>
              </Col>
            )}
            {/* </Row> */}
          </Col>
        ) : cardData?.responseType === "Open" ||
          cardData?.responseType === "Single Select-B" ||
          cardData?.responseType === "Single Select-A" ||
          cardData?.responseType === "Multi Select" ||
          cardData?.responseType === "Likert Scale" ||
          cardData?.responseType === "Single Select" ? (
          <Row>
            <Col span={24}>
              <Row>
                {/* {supp_card_order} {".  "} */}
                {sequence ?? supp_card_order} {".  "}
                {isFullWidth
                  ? cardData.ques_text
                  : cardData.ques_text.length > 25 && !flag
                  ? cardData.ques_text.substring(0, 40) + "..."
                  : cardData.ques_text}
              </Row>
              <Row style={{ padding: 0, margin: 0 }}>
                {cardData.criteria_details
                  .slice(
                    0,
                    flag || isFullWidth ? cardData.criteria_details.length : 1
                  )
                  .map((data, index) => (
                    <Row
                      align="middle"
                      style={{ padding: "0 8px" }}
                      key={index}>
                      <Col>
                        <Tag
                          color={"blue"}
                          style={{
                            padding: "2px 10px",
                            borderRadius: 2,
                            marginTop: 8,
                            width: "auto",
                            wordBreak: (flag || isFullWidth) && "break-all",
                            whiteSpace: (flag || isFullWidth) && "normal",
                          }}>
                          {isFullWidth
                            ? data.criteria_name
                            : data.criteria_name.length > 12 && !flag
                            ? data.criteria_name.substring(0, 12) + "..."
                            : data.criteria_name}{" "}
                          : <strong>{data?.details[0]?.nSize}</strong>
                        </Tag>
                      </Col>
                      <Col>
                        {data.details
                          .slice(
                            0,
                            flag || isFullWidth ? data.details.length : 1
                          )
                          .map((data, index) => (
                            <Row wrap={false} align="middle" key={index}>
                              {data && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                    minWidth: "75px",
                                  }}>
                                  <strong style={{ paddingRight: 8 }}>
                                    {" "}
                                    {data?.value}%
                                  </strong>
                                  {/* <span
                                    style={{
                                      fontWeight: 600,
                                      color: "grey",
                                    }}>
                                    ({data.hover_string})
                                  </span> */}
                                </div>
                              )}
                              <Col style={{ margin: 8 }}>
                                <q>
                                  {isFullWidth
                                    ? data.name
                                    : data.name?.length > 12 && !flag
                                    ? data.name.substring(0, 36) + "..."
                                    : data.name}
                                </q>
                              </Col>
                            </Row>
                          ))}

                        {(flag || isFullWidth) && data.preference && (
                          <Col align="left">
                            <strong>Preference:</strong>{" "}
                            {data.details[0].preference}
                          </Col>
                        )}
                        {/* </Row> */}
                      </Col>
                    </Row>
                  ))}
              </Row>
              {!isFullWidth && (
                <Col span={24}>
                  <Button
                    type="link"
                    onClick={handleViewMoreClick}
                    style={{ color: "#716E79", padding: 0 }}>
                    {!flag ? (
                      <DownOutlined style={{ fontSize: "12px" }} />
                    ) : (
                      <UpOutlined style={{ fontSize: "12px" }} />
                    )}
                    {flag ? "View Less" : "View More"}
                  </Button>
                </Col>
              )}
              {/* </Row> */}
            </Col>
          </Row>
        ) : cardData?.responseType === "Rank Order" ||
          cardData?.responseType === "Grid" ? (
          <Row>
            <Col span={24}>
              {/* {supp_card_order} */}
              {sequence ?? supp_card_order}

              {".  "}
              {isFullWidth
                ? cardData.ques_text
                : cardData.ques_text.length > 25 && !flag
                ? cardData.ques_text.substring(0, 40) + "..."
                : cardData.ques_text}
            </Col>
            <Col span={24}>
              {cardData.criteria_details
                .slice(
                  0,
                  flag || isFullWidth ? cardData.criteria_details.length : 1
                )
                .map((data, index) => (
                  <Row
                    align="middle"
                    style={{ padding: "16px 8px" }}
                    key={index}>
                    <Col style={{ marginBottom: "8px" }}>
                      <Tag
                        color={"blue"}
                        style={{
                          padding: "2px 10px",
                          borderRadius: 2,
                          width: "auto",
                          wordBreak: (flag || isFullWidth) && "break-all",
                          whiteSpace: (flag || isFullWidth) && "normal",
                        }}>
                        {isFullWidth
                          ? data.criteria_name
                          : data.criteria_name.length > 12 && !flag
                          ? data.criteria_name.substring(0, 12) + "..."
                          : data.criteria_name}{" "}
                        : <strong>{data.details[0]?.nSize}</strong>
                      </Tag>
                    </Col>
                    <Col span={24}>
                      {cardData?.responseType === "Grid" ? (
                        <strong>Indication: {cardData.optionName}</strong>
                      ) : (
                        <strong>Ranking: {cardData.optionName}</strong>
                      )}
                    </Col>
                    <Col>
                      {data.details
                        .slice(0, flag || isFullWidth ? data.details.length : 0)
                        .map((data, index) => (
                          <Row
                            wrap={false}
                            align="middle"
                            justify="start"
                            // gutter={[16, 16]}
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <Col
                              style={{
                                padding: "8px",
                                display: "flex",
                                justifyContent: "right",
                              }}
                              flex="85px">
                              <strong>{data.value}%</strong>
                              {"  "}
                              {/* <span
                                style={{
                                  paddingRight: 4,
                                  fontWeight: 600,
                                  color: "grey",
                                }}>
                                {"   "} ({data.hover_string})
                              </span> */}
                            </Col>
                            <Col style={{ flex: "1" }} align="">
                              {cardData?.responseType === "Grid" ? (
                                <div>{data.name}</div>
                              ) : (
                                <div>{getOrdinal(data.name)}</div>
                              )}
                            </Col>
                          </Row>
                        ))}
                    </Col>
                  </Row>
                ))}
              {!isFullWidth && (
                <Col span={24}>
                  <Button
                    type="link"
                    onClick={handleViewMoreClick}
                    style={{ color: "#716E79", padding: 0, margin: 0 }}>
                    {!flag ? (
                      <DownOutlined style={{ fontSize: "12px" }} />
                    ) : (
                      <UpOutlined style={{ fontSize: "12px" }} />
                    )}
                    {flag ? "View Less" : "View More"}
                  </Button>
                </Col>
              )}
            </Col>
          </Row>
        ) : cardData?.responseType === "Preference" ? (
          <Row style={{ padding: 8 }}>
            <Col span={24} style={{ margin: "8px 0px" }}>
              {/* {supp_card_order} */}
              {sequence ?? supp_card_order}
              {". "}
              {reason
                ?.slice(0, flag || isFullWidth ? reason.length : 2)
                .map((data, index) => (
                  <Row gutter={[4]} key={index}>
                    <Col span={24} style={{ padding: 8 }}>
                      <q>
                        {isFullWidth
                          ? data
                          : data?.length > 48 && !flag
                          ? data.substring(0, 48) + "..."
                          : data}
                      </q>
                    </Col>
                  </Row>
                ))}
            </Col>
            <Col span={24}>
              <Tag
                color={"blue"}
                style={{
                  padding: "2px 10px",
                  borderRadius: 2,
                  width: "auto",
                  wordBreak: (flag || isFullWidth) && "break-all",
                  whiteSpace: (flag || isFullWidth) && "normal",
                }}>
                {isFullWidth
                  ? cardData?.respondents
                  : cardData?.respondents?.length > 12 && !flag
                  ? cardData?.respondents?.substring(0, 12) + "..."
                  : cardData?.respondents}{" "}
              </Tag>
            </Col>
            {/* Add other content here with similar styling */}
            {(flag || isFullWidth) && cardData.preference !== "" && (
              <Col
                style={{
                  margin: "4px 0px",
                }}
                span={24}>
                <strong>Preference:</strong> {cardData.preference}
              </Col>
            )}{" "}
            {(flag || isFullWidth) && (
              <Col style={{ margin: "4px 0px" }}>
                <strong> Question:</strong> {cardData.ques_text}
              </Col>
            )}
            {!isFullWidth && (
              <Col span={24} style={{ marginBottom: 8 }}>
                <Button
                  type="link"
                  onClick={handleViewMoreClick}
                  style={{ color: "#716E79", padding: 0 }}>
                  {!flag ? (
                    <DownOutlined style={{ fontSize: "12px" }} />
                  ) : (
                    <UpOutlined style={{ fontSize: "12px" }} />
                  )}
                  {flag ? "View Less" : "View More"}
                </Button>
              </Col>
            )}
          </Row>
        ) : (
          <Row>
            <Col span={24}>
              <Row>
                {/* {supp_card_order}. */}
                {sequence ?? supp_card_order}.{"  "}
                {isFullWidth
                  ? cardData.ques_text
                  : cardData.ques_text.length > 48 && !flag
                  ? cardData.ques_text.substring(0, 48) + "..."
                  : cardData.ques_text}
              </Row>
              <Row style={{ padding: 0, margin: 0 }}>
                {cardData.criteria_details
                  .slice(
                    0,
                    flag || isFullWidth ? cardData.criteria_details.length : 1
                  )
                  .map((data, index) => (
                    <Row style={{ padding: "0 8px" }} key={index}>
                      <Col
                        style={{ margin: "8px 0px", fontSize: "14px" }}
                        span={24}>
                        <Tag
                          color={"blue"}
                          style={{
                            padding: "2px 10px",
                            borderRadius: 2,
                            width: "auto",
                            wordBreak: (flag || isFullWidth) && "break-all",
                            whiteSpace: (flag || isFullWidth) && "normal",
                          }}>
                          {isFullWidth
                            ? data.criteria_name
                            : data.criteria_name.length > 8 && !flag
                            ? data.criteria_name.substring(0, 8) + "..."
                            : data.criteria_name}
                          : <strong>{data?.details[0]?.nSize}</strong>
                        </Tag>
                      </Col>

                      <Col span={24}>
                        {/* <Row
                        gutter={[8, 4]}
                        wrap={isFullWidth || flag ? true : false}
                        align="middle"
                      > */}
                        {data.details
                          .slice(
                            0,
                            flag || isFullWidth ? data.details.length : 1
                          )
                          .map((data, index) => (
                            <Row wrap={false} align="middle" key={index}>
                              {data ? (
                                // <Col style={{ padding: 8 }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                    minWidth: "75px",
                                  }}>
                                  <strong style={{ paddingRight: 8 }}>
                                    {" "}
                                    {data?.perc_respondents}%{"  "}{" "}
                                  </strong>
                                  {"   "}
                                  {/* <span
                                    style={{
                                      fontWeight: 600,
                                      color: "grey",
                                    }}>
                                    {"   "}({data.hover_string})
                                  </span>{" "} */}
                                </div>
                              ) : // </Col>
                              null}
                              <Col style={{ margin: 8 }}>
                                <q>
                                  {isFullWidth
                                    ? data.response
                                    : data.response?.length > 36 && !flag
                                    ? data.response.substring(0, 36) + "..."
                                    : data.response}
                                </q>
                              </Col>
                            </Row>
                          ))}

                        {(flag || isFullWidth) && data.preference && (
                          <Col>
                            <strong>Preference:</strong>{" "}
                            {data.details[0].preference}
                          </Col>
                        )}
                      </Col>
                    </Row>
                  ))}
              </Row>

              {!isFullWidth && (
                <Col span={24}>
                  <Button
                    type="link"
                    onClick={handleViewMoreClick}
                    style={{ color: "#716E79", padding: 0 }}>
                    {!flag ? (
                      <DownOutlined style={{ fontSize: "12px" }} />
                    ) : (
                      <UpOutlined style={{ fontSize: "12px" }} />
                    )}
                    {flag ? "View Less" : "View More"}
                  </Button>
                </Col>
              )}
              {/* </Row> */}
            </Col>
          </Row>
        )}
      </Row>
    </Col>
  );
};

export default ResponseCard;
