export const LIKERT_COLOR_PALETTE = [
  "#0952CA",
  "#027AFF",
  "#7CC0FF",
  "#DEDCDE",
  "#FFB234",
  "#EC7200",
  "#A54F00",
];
export const LIKERT_COLOR_PALETTE_WITH_FIVE_COLORS = [
  "#0952CA",
  "#027AFF",

  "#DEDCDE",

  "#EC7200",
  "#A54F00",
];

export const COLOR_PALETTE_THREE_RANK = [
  {
    rank: "1st",
    colorCode: "#0952CA",
  },
  {
    rank: "2nd",
    colorCode: "#027AFF",
  },
  {
    rank: "3rd",
    colorCode: "#B2B0B6",
  },
];

export const COLOR_PALETTE_FOUR_RANK = [
  {
    rank: "1st",
    colorCode: "#0952CA",
  },
  {
    rank: "2nd",
    colorCode: "#027AFF",
  },
  {
    rank: "3rd",
    colorCode: "#87848D",
  },
  {
    rank: "4th",
    colorCode: "#B2B0B6",
  },
];

export const COLOR_PALETTE_FIVE_RANK = [
  {
    rank: "1st",
    colorCode: "#0952CA",
  },
  {
    rank: "2nd",
    colorCode: "#027AFF",
  },
  {
    rank: "3rd",
    colorCode: "#7CC0FF",
  },
  {
    rank: "4th",
    colorCode: "#87848D",
  },
  {
    rank: "5th",
    colorCode: "#B2B0B6",
  },
];

export const COLOR_PALETTE_SIX_RANK = [
  {
    rank: "1st",
    colorCode: "#0952CA",
  },
  {
    rank: "2nd",
    colorCode: "#027AFF",
  },
  {
    rank: "3rd",
    colorCode: "#7CC0FF",
  },
  {
    rank: "4th",
    colorCode: "#87848D",
  },
  {
    rank: "5th",
    colorCode: "#B2B0B6",
  },
  {
    rank: "6th",
    colorCode: "#DEDCDE",
  },
];

export const COLOR_PALETTE_SEVEN_RANK = [
  {
    rank: "1st",
    colorCode: "#000077",
  },
  {
    rank: "2nd",
    colorCode: "#0952CA",
  },
  {
    rank: "3rd",
    colorCode: "#027AFF",
  },
  {
    rank: "4th",
    colorCode: "#7CC0FF",
  },
  {
    rank: "5th",
    colorCode: "#87848D",
  },
  {
    rank: "6th",
    colorCode: "#B2B0B6",
  },
  {
    rank: "7th",
    colorCode: "#DEDCDE",
  },
];

export const GRID_TYPE_COLOR_PALETTE_THREE = [
  "#027AFF",
  "#EC7200",
  "#DEDCDE",
  
];

export const GRID_TYPE_COLOR_PALETTE_FOUR = [
  "#027AFF",
  "#7CC0FF",
  "#FFB234",
  "#EC7200",
];

export const GRID_TYPE_COLOR_PALETTE_SEVEN = [
  "#0952CA",
  "#027AFF",
  "#7CC0FF",
  "#DEDCDE",
  "#FFB234",
  "#EC7200",
  "#A54F00",
];

export const GRID_TYPE_COLOR_PALETTE_FIVE = [
  "#0952CA",
  "#7CC0FF",
  "#DEDCDE",
  "#FFB234",
  "#A54F00",
];

export const GRID_TYPE_COLOR_PALETTE_SIX = [
  "#0952CA",
  "#027AFF",
  "#DEDCDE",
  "#B2B0B6",
  "#E77003",
  "#B54708",
  // "#A54F00", 
];