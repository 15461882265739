import React, { useState, useEffect } from "react";
import { Table, Button, Drawer, Space } from "antd";
import './DataUpdates.scss'
import NotificationIcon from '../../assets/icons/notification-icon.svg'

const DataUpdates = (props) => {
  const [open, setOpen] = useState(props.visible);
  const [size, setSize] = useState();
  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    props.onClose();
  };

  useEffect(() => {
    showLargeDrawer();
  }, []);

  const columns = [
    {
        title: "Release Date",
        dataIndex: "releaseDate",
        defaultSortOrder: "descend",
      },
      {
        title: "TA/Disease",
        dataIndex: "disease",
      },
      {
        title: "Sample Size",
        dataIndex: "sampleSize",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.sampleSize - b.sampleSize,
      },
    {
      title: "Regions",
      dataIndex: "regions",
      filters: [
        {
          text: "APAC",
          value: "APAC",
        },
        {
          text: "EU",
          value: "EU",
        },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.regions.indexOf(value) === 0,
    },
  
    {
      title: "Data Category Coverage",
      dataIndex: "coverage",
      filters: [
        {
          text: "London",
          value: "London",
        },
        {
          text: "New York",
          value: "New York",
        },
      ],
      onFilter: (value, record) => record.address.indexOf(value) === 0,
    },
  ];
  const data = [
    {
      key: "1",
      regions: "APAC",
      sampleSize: 320,
      releaseDate:'13 Dec 2022',
      disease: 'Oncology/Multiple Myeloma',
      coverage: "Care Delivery Preference",
    },
    {
      key: "2",
      regions: "EU",
      sampleSize: 1142,
      releaseDate:'13 Dec 2022',
      disease: 'Oncology/Multiple Myeloma',
      coverage: "Clinical Trial Perception;Information & Communication",
    },
    {
      key: "3",
      regions: "NA",
      sampleSize: 302,
      releaseDate:'13 Dec 2022',
      disease: 'Oncology/Multiple Myeloma',
      coverage: "Digital Access & Aptitude",
    },
    {
      key: "4",
      regions: "APAC, EU",
      sampleSize: 732,
      releaseDate:'13 Dec 2022',
      disease: 'Oncology/Multiple Myeloma',
      coverage: "Clinical Trial Perception;Information & Communication",
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
  };

  return (
    <>
      <Drawer
        title={`Data Updates`}
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
        width={1100}
        footer={
          <Space style={{ float: "right" }}>
            <Button onClick={onClose}>Close</Button>
          </Space>
        }
      >
        <div>
        <img alt={"Data Updates Icon"} src={NotificationIcon} style={{verticalAlign:'bottom'}}/>
          <span className="label-text">25 new surveys are availble to view.</span>
        </div>
        <div style={{marginTop:24}}>
          <Table columns={columns} dataSource={data} onChange={onChange} />
        </div>
      </Drawer>
    </>
  );
};

export default DataUpdates;
